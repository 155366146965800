<template>
  <base-header>
    <template v-slot:right>
      <ion-menu-button auto-hide="false" @click="toSettings"></ion-menu-button>
    </template>
    <template v-slot:left>
      <slot name="left"></slot>
    </template>
  </base-header>
</template>

<script>
import BaseHeader from "@/components/BaseHeader";
import useHeader from "@/use/useHeader";
import { IonMenuButton } from "@ionic/vue";
import { chevronBackCircleOutline } from "ionicons/icons";

export default {
  name: 'home-header',
  components: {BaseHeader, IonMenuButton},
  props: {
    fixed: Boolean,
    title: String
  },
  setup(props) {
    const {state, toRoute, toSettings} = useHeader(props);

    
    return {state, toRoute, toSettings, chevronBackCircleOutline}
  }
};

</script>

<style lang="css">
:root {
  -color-blue: var(--ion-color-active-darkest);
}

.header {
  --ion-color-primary: #FFF;
  max-height: 53px;
}

ion-header {
  max-height: 53px;
}

.color-white {
  color: var(ion-color-primary-contrast);
}

ion-back-button {
  --color: var(ion-color-primary-contrast);
  --margin-start: 10px;
}
</style>
