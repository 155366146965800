<template>
  <button class="code-button login-button-color ion-margin-top d-flex" type="button">
    <img class="icon" src='@/assets/img/icons/tv-paired-icon.svg'>
  </button>
</template>

<script>

export default {
  name: "PairedLeftButton",
  components: {},
  props: ['text', 'icon'],

}
</script>

<style scoped>
.code-button {
  display: block;
  margin: 0 auto;
  text-align: center;
  border: 0px;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 25px 10px 10px;
  background: transparent;
  font-family: 'Proxima Nova Rg';
  font-size: 1.2rem;
  color: var(--ion-color-primary-text);
}

.line-height {
  line-height: 0;
}

.d-flex {
  display: flex;
  align-items: center;

}

.icon {
  width: 34px;
  height: 27px;
}
</style>
