<template>
 <base-header>
    <template v-slot:right>
      <left-button :icon="'close-outline'" @click="dismissModal"></left-button>
    </template>
    <template v-slot:left>
      <PairedLeftButton @click="toRoute('/tvsettings')"></PairedLeftButton>
    </template>
  </base-header>
  <ion-content>
      <div class="text-align-centre login-text">
        <span class="info-text">PAGE NOT FOUND</span>
      </div>

      <div class="container-items">
        <ion-row class="notfound-item">
          <ion-col>
            <span class="item-span">INFORMATION<br />NOT AVAILABLE</span>
          </ion-col>
        </ion-row>
        <ion-row class="notfound-item">
          <ion-col>
            <button class="code-button code-button-color ion-margin-top" type="button"  @click="toRoute('/home')">CONTINUE</button>
          </ion-col>
        </ion-row>
      </div>

    </ion-content>
</template>

<script>


import {defineComponent} from "vue";
import {IonCol, IonRow,} from "@ionic/vue";
import BaseHeader from "@/components/BaseHeader";
import {addIcons} from "ionicons";
import {closeOutline, menuOutline} from "ionicons/icons";
import {arrowBackOutline} from "ionicons/icons";
import PairedLeftButton from "@/components/PairedLeftButton";
import useNotfound from "@/use/useNotfound";


export default defineComponent({
  name: "Notfound",
  components: {IonCol, IonRow, BaseHeader, PairedLeftButton},
  props: ['title', 'dismiss'],
  setup(props) {
    const {state, toRoute} = useNotfound(props);
    addIcons({
      'close-outline': closeOutline,
      'back-outline': arrowBackOutline,
      'menu-outline': menuOutline
    });

    const dismissModal = () => {
      props.dismiss();
    }

    return {
      dismissModal, toRoute
    }
  }
})
</script>

<style scoped>
.code-button {
  display: block;
  margin: 0 auto;
  text-align: center;
  border: 0;
  /*padding: 10px;*/
  background: transparent;
  font-size: 30px;
  color: var(--ion-color-primary-text);
}
ion-modal {
  width: 84.615vw !important;
}

ion-content {
  --background: linear-gradient(180deg, var(--ion-color-active-dark) 0%, var(--ion-color-active-darker) 100%);
}
ion-grid {
    width: 84.615vw;
    margin: 56px auto 0 auto;
    padding: 0;
}
ion-row {
    margin-bottom: 10px;
    position: relative;
}
ion-col {
  margin: 0 34px;
  max-width: calc(100% - 68px);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-text {
  font-family: 'Industry Black';
  font-size: 25px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 42px 0 40px 0;
  color: var(--ion-color-primary-text);
  text-transform: uppercase;
}
.container-items {
  width: 84.615vw;
  height: 495px;
  position: relative;
  margin: 0 auto;
  padding-top: 24px;
}
.container-items:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(180deg, var(--ion-color-active) 0%, var( --ion-color-passive) 100%);
  opacity: 0.2;
  filter: var(--ion-filter-dark);
  border-radius: 16px;
  z-index: -1;
}
.item-span {
  font-family: 'Proxima Nova Bold';
  font-size: 18px;
  line-height: 27px;
  color: var(--ion-color-primary-text);
  margin: 100px 0 160px 0;
  text-align: center;
}
.notfound-item .code-button {
  display: block;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  font-family: 'Industry Black';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: 0.38px;
  color: var(--ion-color-primary-text);
}
.notfound-item .code-button-color {
    background: var(--ion-color-active);
    color: var(--ion-color-primary-text);
}
  
</style>
