export interface User {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  displayName: string;
}
export interface Toggles {
  bar: boolean;
  qa: boolean;
  fantasy: boolean;
  ppr: boolean;
  insights: boolean;
}

export enum ErrorType {
  WrongUserPwd,
  Other,
  UserNotFound,
  EmailExists,
  PasswordNotConform,
  InvalidPassword,
}

export interface ScoreHistory {
  answerRight: string[];
  answerUser: string;
  quesiton: string;
  scoreParticipation: string;
  type: string;
}[]