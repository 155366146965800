<template>
  <button class="code-button login-button-color ion-margin-top d-flex" type="button">
    <span v-if="text" class="line-height">{{ text }}</span>
    <ion-icon class="icon" :name="icon"></ion-icon>
  </button>
</template>

<script>
import {IonIcon} from "@ionic/vue";

export default {
  name: "LeftButton",
  components: {IonIcon},
  props: ['text', 'icon'],
  setup(props, context) {

    return {}
  }
}
</script>

<style scoped>
.code-button {
  display: block;
  margin: 0 auto;
  /*margin-top: 16px;*/
  /*margin-bottom: 16px;*/
  text-align: center;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 6.66667px;
  /*padding: 10px;*/
  background: #0b233d;
  font-family: "Proxima Nova ExCn Rg";
  font-size: 16px;
  color: var(--ion-color-primary-text);
  height: 26px;
}

.line-height {
  /*line-height: 19.49px;*/
}

.d-flex {
  display: flex;
  align-items: center;

}

.icon {
  flex: 1 1 1rem;
}
</style>
