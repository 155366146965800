import { reactive } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { onMounted } from "@vue/runtime-core";
import { BEService } from '@/service/backend.service';
import moment from "moment";
import "moment-timezone";

export default function useHome() {
    const router = useRouter();
    const state = reactive({
        loaded : false,
        date: "",
        time: "",
        away: {
            alias: "",
            logo: "",
            lable: ""
        },
        home: {
            alias: "",
            logo: "",
            lable: ""
        },
    });

    const toRoute = (route) => {
        router.push(route);
    };

    function convertMillisecondsToLocalTime(utcMilliseconds) {
        if (utcMilliseconds != null && utcMilliseconds != "") {
            const zone = moment.tz.guess();
            const abbr = moment.tz(zone).format("z");
            const hhmm = moment(parseFloat(utcMilliseconds)).format('h:mm Az') + " " + abbr;
            return hhmm;
        } else {
            return utcMilliseconds;
        }
    }

    onMounted(async () => {
        state.loaded = false;
        const beService = new BEService();
        try {
            const mainEvent = await beService.getMainEvent();
            if (mainEvent) {
                if (mainEvent.isInprogress) {
                    toRoute("/staytuned");
                    return;
                }
                if (mainEvent.exists){
                    const localDate = new Date(mainEvent.timematch);
                    const month = localDate.getUTCMonth() + 1; //months from 1-12
                    const day = localDate.getUTCDate();
                    const year = localDate.getUTCFullYear();
                    state.date = month + "/" + day + "/" + year;
                    state.time = convertMillisecondsToLocalTime(mainEvent.timematch);
                }
                state.away = mainEvent.away;
                state.home = mainEvent.home;
                state.loaded = true;
            }
        } catch (error) {
            console.log("Error on getMainEvent",error);
        }

    });
    return { state, toRoute };
}
