import { useRouter } from "vue-router";

export default function useStaytuned() {
    const router = useRouter();

    const toRoute = (route) => {
        router.push(route);
    };
    return { toRoute };
}
