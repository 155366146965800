import { AuthService } from '@/service/auth.service';
import { Security } from '@/service/security';
import axios from 'axios';
const auth = new AuthService();
const security = new Security();
const baseURL = process.env.VUE_APP_ROOT_API
console.log("baseurl", baseURL);

const axiosApiInstance = axios.create();
axiosApiInstance.defaults.timeout = 5000

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async config => {
    const token = security.getToken();
    config.headers = {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
    }
    config.baseURL = baseURL;
    return config;
  },
  error => {
    Promise.reject(error)
  });

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async  (error)=> {
  console.log("Interceptor error",error);
  const originalRequest = error.config;

  if (error?.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    console.log("Token expired");
    const token = localStorage.getItem("token") || "";
    const userID = auth.getCurrentUsername() || "";
    const newToken = await security.getRefreshedToken(axiosApiInstance, token, userID);
    if (!newToken) {
      throw new Error(`error on retrieving refreshed token`);
    }
    console.log("Token renewed");
    security.saveToken(newToken);

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return axiosApiInstance(originalRequest);
  }
  return Promise.reject(error);
});



//const http = axios;
export default axiosApiInstance
