<template>
    <ion-page>
        <base-header>
            <template v-slot:right>
                <left-button :icon="'close-outline'" @click='$router.go(-1)'></left-button>
            </template>
            <template v-slot:left>
                <PairedLeftButton @click="toRoute('/tvsettings')"></PairedLeftButton>
            </template>
        </base-header>
        <ion-content>
            <div class="text-align-centre login-text">
                <span class="info-text">TERMS OF USE</span>
            </div>

            <div class="container-items">
                <ion-row>
                    <ion-col>
                        <span class="item-span">Thank you for agreeing to participate in application testing to evaluate
                            new products that are in the early stages of development. The products themselves, and the
                            ideas and concepts regarding its manufacture, marketing, use, and the discussions here
                            represent Confidential Information of the company sponsoring (“Sponsor”) this research study
                            / discussion (“Test Study”).

                            You agree to maintain the confidentiality of information disclosed during testing or
                            observed live as follows:
                            <br>
                            <br>
                            a) To hold in confidence any and all technical or business information about the company’s
                            product which is disclosed, or made available to you directly or indirectly, or is
                            information you otherwise receive incident to your participation in this test.
                            <br>
                            <br>
                            b) That any ideas, patentable or not patentable, or suggestions contributed by you during
                            the discussion, as well as any ideas, developments, or inventions conceived by you or others
                            participating in the Test Study, shall be the property of the company sponsoring this
                            research study in any manner it sees fit.
                            <br>
                            <br>
                            c) The products shown to you, described to you, and/or used by you are not available for
                            sale and no offer for sale is being made to you.
                            <br>
                            <br>
                            d) That you, shall at all times hold in trust, keep confidential and not disclose to any
                            third party or make any use of the Confidential Information beyond those activities that are
                            part of the Test Study.
                            <br>
                            <br>
                            e) All notes, reference materials, memoranda, documentation and records in any way
                            incorporating or reflecting any of the Confidential Information shall belong exclusively to
                            the Sponsor and the undersigned agrees to turn over all copies of such materials in the
                            undersigned’s possession to the Sponsor upon request.
                            <br>
                            <br>
                            f) Also included as confidential is any participants Personally Identifiable Information
                            (“PII”). PII shall mean a person’s identity or information that might reasonably allow
                            identification of the person. I shall at all times hold in trust, keep confidential and not
                            disclose to any third party or make any use of the identity or PII of any Respondent
                            involved in the Test Study.
                            <br>
                            <br>
                            g) That you acknowledge you are being sufficiently compensated for your participation in
                            this Test Study and that all information and opinions you provide are solely your own and
                            are in no way reflective of your employer(s).
                            <br>
                            <br>
                            The company sponsoring this study is Verance Corporation, 6046 Cornerstone Ct West, Suite
                            216, San Diego, CA 92121.

                            

                        </span>
                    </ion-col>
                </ion-row>
            </div>

        </ion-content>
    </ion-page>
</template>
  
<script>



import BaseHeader from "@/components/BaseHeader";
import LeftButton from "@/components/LeftButton";
import PairedLeftButton from "@/components/PairedLeftButton";
import { getSettings } from "@/use/useSettings";
import { IonCol, IonContent, IonPage, IonRow } from "@ionic/vue";
import { onMounted } from "@vue/runtime-core";
import { addIcons } from "ionicons";
import { arrowBackOutline, closeOutline } from "ionicons/icons";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "Termsofuse",
    components: { LeftButton, IonPage, IonContent, IonCol, IonRow, BaseHeader, PairedLeftButton },
    props: ['title', 'dismiss'],
    setup(props) {
        addIcons({
            'close-outline': closeOutline,
            'back-outline': arrowBackOutline
        });

        const dismissModal = () => {
            props.dismiss();
        }
        const userSettings = getSettings();

        const onSettingsChange = async ($event) => {
            //TODO add code
        };

        onMounted(async () => {
            //TODO add code
        });
        const router = useRouter();

        const toRoute = (route) => {
            router.push(route);
        }
        return {
            dismissModal,
            userSettings,
            onSettingsChange,
            toRoute
        }
    }
})
</script>
  
<style scoped>
.code-button {
    display: block;
    margin: 0 auto;
    text-align: center;
    border: 0;
    padding: 0 10px;
    background: transparent;
    font-size: 30px;
    color: var(--ion-color-primary-text);
}

ion-modal {
    width: 84.615vw !important;
}

ion-content {
    --background: linear-gradient(180deg, var(--ion-color-active-dark) 0%, var(--ion-color-active-darker) 100%);
}

ion-grid {
    width: 84.615vw;
    margin: 56px auto 0 auto;
    padding: 0;
}

ion-row {
    margin-bottom: 10px;
    position: relative;
}

.info-text {
    font-family: 'Industry Black';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 38px 0 50px 0;
    color: var(--ion-color-primary-text);
    text-transform: uppercase;
}

.container-items {
    width: 84.615vw;
    height: 495px;
    position: relative;
    margin: 0 auto;
    padding-top: 24px;
}

.container-items:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(180deg, var(--ion-color-active) 0%, var(--ion-color-passive) 100%);
    opacity: 0.2;
    filter: var(--ion-filter-dark);
    border-radius: 16px;
    z-index: -1;
}

ion-col {
    margin: 0 16px;
    padding: 0;
    max-width: calc(100% - 32px);
    display: flex;
}

.item-span {
    font-family: 'Proxima Nova RG';
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.38px;
    color: var(--ion-color-primary-text);
}
</style>
  