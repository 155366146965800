<template>
  <ion-page>
    <base-header>
      <template v-slot:right>
        <left-button :icon="'close-outline'" @click='$router.go(-1)'></left-button>
      </template>
      <template v-slot:left>
        <PairedLeftButton @click="toRoute('/tvsettings')"></PairedLeftButton>
      </template>
    </base-header>
    <ion-content>
      <div class="text-align-centre login-text">
        <span class="info-text">Leaderboard</span>
      </div>

      <div class="container-items">


        <div class="code-error" v-if="state.serverError">{{ state.serverError }}</div>
        <ion-row class="leaderboard-item">
          <ion-col>
            <span class="item-span">CURRENT SCORE</span>
            <span class="item-span">{{ state.currentscore }}</span>
          </ion-col>
        </ion-row>
        <ion-row class="leaderboard-list" v-for="(item, index) in state.leaderboard" :key="item">
          <ion-col>
            <span class="ranking-span">{{ index + 1 }}</span>
            <span class="item-span">{{ item.name }}</span>
            <span class="item-span">{{ item.score }}</span>
          </ion-col>
        </ion-row>
      </div>

    </ion-content>
  </ion-page>
</template>

<script>


import BaseHeader from "@/components/BaseHeader";
import LeftButton from "@/components/LeftButton";
import PairedLeftButton from "@/components/PairedLeftButton";
import useLeaderboard from "@/use/useLeaderboard";
import { IonCol, IonContent, IonPage, IonRow } from "@ionic/vue";
import { addIcons } from "ionicons";
import { arrowBackOutline, closeOutline } from "ionicons/icons";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Leaderboard",
  components: { LeftButton, IonPage, IonContent, IonCol, IonRow, BaseHeader, PairedLeftButton },
  props: ['title', 'dismiss'],
  setup(props) {
    const { state, toRoute } = useLeaderboard(props);
    addIcons({
      'close-outline': closeOutline,
      'back-outline': arrowBackOutline
    });
    const dismissModal = () => {
      props.dismiss();
    }

    return { state, dismissModal, toRoute }
  }


})
</script>

<style scoped>
.code-button {
  display: block;
  margin: 0 auto;
  text-align: center;
  border: 0;
  padding: 0 10px;
  background: transparent;
  font-size: 30px;
  color: var(--ion-color-primary-text);
}

ion-modal {
  width: 84.615vw !important;
}

ion-content {
  --background: linear-gradient(180deg, var(--ion-color-active-dark) 0%, var(--ion-color-active-darker) 100%);
}

ion-grid {
  width: 84.615vw;
  margin: 56px auto 0 auto;
  padding: 0;
}

ion-row {
  margin-bottom: 10px;
  position: relative;
}

.code-error {
  font-family: 'Proxima Nova RG';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  color: var(--ion-color-right);
  position: absolute;
  top: 10%;
  left: 22%;
  transform: translate(-22%, -10%);
}

ion-row:first-child:after {
  content: "";
  display: block;
  width: 100%;
  border-bottom: 1px solid var(--ion-color-primary-border);
  position: relative;
  opacity: 0.3;
  bottom: -21px;
}

ion-row:after:last-child {
  border-bottom: none;
}

ion-row.leaderboard-list {
  margin-bottom: 20px;
}

.leaderboard-item {
  margin: 0 0 45px 0;
}

.leaderboard-item,
.leaderboard-list {
  padding: 0 18px;
}

.leaderboard-list .item-span {
  color: var(--ion-color-primary-text);
  width: calc(80% - 30px);
}

.leaderboard-list .item-span:last-child {
  width: 20%;
  text-align: right;
}

.info-text {
  font-family: 'Industry Black';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 38px 0 50px 0;
  color: var(--ion-color-primary-text);
  text-transform: uppercase;
}

.container-items {
  width: 84.615vw;
  height: 495px;
  position: relative;
  margin: 0 auto;
  padding-top: 24px;
}

.container-items:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(180deg, var(--ion-color-active) 0%, var(--ion-color-passive) 100%);
  opacity: 0.2;
  filter: var(--ion-filter-dark);
  border-radius: 16px;
  z-index: -1;
}

.item-span {
  font-family: 'Proxima Nova Bold';
  font-size: 18px;
  line-height: 23px;
  color: var(--ion-color-right);
  text-transform: uppercase;
}

ion-col {
  margin: 0 16px;
  padding: 0;
  max-width: calc(100% - 32px);
  display: flex;
}

.leaderboard-item ion-col {
  justify-content: space-between;
}

.ranking-span {
  background: var(--ion-color-bg-right);
  width: 23px;
  height: 23px;
  display: block;
  border-radius: 50%;
  line-height: 21px;
  text-align: center;
  font-family: 'Industry Black';
  font-size: 15px;
  letter-spacing: 0.38px;
  color: var(--ion-color-active-darkest);
  margin-right: 7px;
}
</style>
