<template>
  <ion-page>
    <!--    <Header fixed title="">-->
    <!--    </Header>-->
    <Header/>

    <ion-content>
      <div class="text-align-centre ">
        <img class="" src='@/assets/img/Banner/Boom.png'>

      </div>
      <div class="text-align-centre ion-margin-top">
        <img class="banner-free-image" src='@/assets/img/Banner/Boom_free_bet.png'>
      </div>
      <div class="description-div margin-0-auto text-align-centre">
        <span class="description-text">Sign up or Login to complete your activation.</span>
      </div>
      <div class="text-align-centre ion-margin-top">
        <form>
          <button class="code-button ion-margin-top" type="button" :disabled="!state.code" @click="buttonClick">CONTINUE
          </button>
        </form>
      </div>
    </ion-content>


  </ion-page>
</template>
<script>

import useCodeAccepted from "@/use/useCodeAccepted";
import {IonPage} from "@ionic/vue";
import Header from "@/components/Header";

export default {
  name: 'CodeAccepted',
  components: {Header, IonPage},
  setup(props) {
    const {state, buttonClick} = useCodeAccepted(props);
    return {state, buttonClick}
  }
}
</script>


<style scoped>

.pairing-text {
  font-family: Industry;
  font-style: normal;
  font-weight: bold;
  line-height: 53px;
}

.description-text {
  font-family: "Proxima Nova Rg";
  font-style: normal;
  font-size: small;
  color: rgba(0, 46, 87, 1);
}

.text-align-centre {
  text-align: center;
}

.margin-0-auto {
  margin: 0 auto;
}

.description-div {

  width: 55vw;
}

.code-input {
  display: block;
  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px;
  width: 60%;
  color: var(--ion-color-active-dark);
  text-transform: uppercase;
  font-family: Industry;
  font-weight: bold;
}

.code-button {
  display: block;
  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
  width: 60%;
  background: var(--ion-color-active);
  font-family: "Proxima Nova ExCn Rg";
  font-size: 1.2rem;
  color: var(--ion-color-primary-text);
}


.banner-free-image {
  max-width: 90vw;

}

.code-button:disabled, .code-button[disabled] {
  background: rgba(68, 150, 248, 0.5);
}
</style>
