import { computed, reactive } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { AuthService } from '@/service/auth.service';
import { ErrorType } from '@/service/user.model';


export default function useFPsendCode() {
    const router = useRouter();
    const state = reactive({
        username: ""
    });

    const toRoute = (route) => {
        router.push(route);
    };

    const isButtonValid = computed(() => {
        return !!state.username;
    });

    const sendCode = () => {
        const auth = new AuthService();
        auth.recoverPasswordSendCode(state.username).then(() => {
            sessionStorage.setItem("forgot-password-email", state.username);
            toRoute('/verificationpage');
        }).catch((err) => {
            console.error("sendCode - " + err.message);
            toRoute('/signin');
        });

    }
    return { state, isButtonValid, sendCode, toRoute };
}
