import { AuthService } from '@/service/auth.service';
import { ErrorType } from '@/service/user.model';
import { computed, reactive } from "@vue/reactivity";
import { useRouter } from "vue-router";


export default function useSignInForm() {
    const router = useRouter();
    const state = reactive({
        username: "",
        password: "",
        isPasswordIncorrect: false,
        isGenericError: false,
        error: "",
    });

    const toRoute = (route) => {
        router.push(route);
    };

    const isButtonValid = computed(() => {
        return !!state.username && !!state.password;
    });

    const login = () => {

        console.log("Login...");
        const auth = new AuthService();
        console.log("Auth", auth)
        auth.signIn(state.username, state.password)
            .then(() => {
                console.log("Go to home page")
                toRoute('/home');
            })
            .catch((err) => {
                console.error("Errore", err)
                if (err === ErrorType.WrongUserPwd) {
                    state.isPasswordIncorrect = true;
                    state.isGenericError = false;
                } else if (err === ErrorType.Other) {
                    state.isGenericError = true;
                    state.isPasswordIncorrect = false;
                }
                else if (err === ErrorType.UserNotFound) {
                    state.error = 'User does not exist';
                }

            });

    }
    return { state, isButtonValid, login, toRoute };
}
