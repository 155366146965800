import { AuthService } from '@/service/auth.service';
import { BEService } from '@/service/backend.service';
import { reactive } from "@vue/reactivity";
import { computed, onMounted } from "@vue/runtime-core";
import { getCurrentInstance } from 'vue';
import { useRouter } from "vue-router";
export default function useQuestion() {
  const router = useRouter();
  const state = reactive({
    idQuestion: null,
    question: "",
    answers: [],
    timer: 60,
    answered: false,
    errorMessage: null,
  });
  let countdown = null;

  const app = getCurrentInstance()
  const socket = app?.appContext.config.globalProperties.$websocket;


  const toRoute = (route) => {
    router.push(route);
  };

  const onClick = async (index, textAnswer) => {
    clearInterval(countdown);


    console.log('Question:', state);
    const beService = new BEService();
    const auth = new AuthService();

    try {
      const data = await beService.setGameAnswer(auth.getCurrentUsername(), state.question.idQuestion, state.question.question, textAnswer);
      //Close the modal/window
      localStorage.timer = 60
      localStorage.answered = true
      state.answered = true
      if (!data.data.error) {
        router.push('staytuned');
      } else {
        state.errorMessage = data.data.error;
      }


    } catch (error) {
      localStorage.answered = false
      state.answered = false
      console.log(error);
    }
  };


  const closed = () => {
    clearInterval(countdown);
    state.errorMessage = null;
  }

  const zeroPadding = computed(() => state.timer < 10 ? state.timer.toString().padStart(2, "0") : state.timer)

  const timerStart = () => {
    countdown = setInterval(() => {
      if (state.timer === (0)) {
        clearInterval(countdown);
        state.timer = 60
        localStorage.timer = 60
        router.push('/staytuned')
      } else {
        state.timer--;
        localStorage.timer = state.timer
      }
    }, 1000);
  }

  socket.on('question', (data) => {
    state.question={...data}
    localStorage.question = JSON.stringify({...data})
    localStorage.timer=60
    clearInterval(countdown);
    state.timer = 60

    timerStart()
  });

  onMounted(async () => {
    console.log("%cOnMounted useQuestion triggered. Timer: ","color:tomato" , localStorage.timer);
    state.question = localStorage.question ? { ...JSON.parse(localStorage.question) } : {}

    let storageTimer = 0;
    if (localStorage.timer) {
      storageTimer = parseInt(localStorage.timer);
      state.timer = storageTimer;
    }else{
      state.timer = 60;
    }

    console.log(state.timer);
    timerStart()

  })


  return { state, toRoute, onClick, closed, timerStart, zeroPadding }
}
