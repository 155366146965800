import { computed, reactive } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { AuthService } from '@/service/auth.service';


export default function useFPNewPassword() {
    const router = useRouter();
    const state = reactive({
        valid: true,
        vcode: true,
        code: "",
        password: "",
        repassword: ""
    });

    const toRoute = (route) => {
        router.push(route);
    };

    const isButtonValid = computed(() => {
        return !!state.code && !!state.password && !!state.repassword;
    });

    const confirm = () => {
        if (state.password !== state.repassword) {
            state.valid = false;
            return;
        }
        const auth = new AuthService();
        const email = sessionStorage.getItem("forgot-password-email");
        auth.recoverPasswordReset(email, state.code, state.password).then(() => {
            toRoute('/signin');
        }).catch((err) => {
            console.error("confirm - " + err.message);
            state.vcode = false;
        });
    }
    return { state, isButtonValid, confirm, toRoute };
}
