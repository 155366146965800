
import {reactive} from "@vue/reactivity";
import {useRouter} from "vue-router";
//import {modalController} from "@ionic/core";
//import Homecopia from "@/views/home/Homecopia";
//import Settings from "@/views/Settings/Settings";

const _settings = reactive({ state: {}});

const router = useRouter();

const toRoute = (route) => {
    router.push(route);
  }
  

export function getSettings() {
    //return _settings;
    return {toRoute, _settings};
}