<template>
  <base-header>
    <template v-slot:right>
      <left-button :icon="'close-outline'" @click="dismissModal"></left-button>
    </template>
    <template v-slot:left>
      <PairedLeftButton></PairedLeftButton>
    </template>
  </base-header>
  <ion-content fullscreen>
    <ion-grid>
      <ion-row v-for="(menu, m) in settingsMenu" :key="m">
        <ion-col >
          <router-link class="item-span" :to='`${menu.route}`'>{{menu.label}}<img class="mint-header-image" src='@/assets/img/icons/r-arrow.svg'></router-link>
        </ion-col>
      </ion-row>
      <!-- <ion-row>
        <ion-col @click='dismissModal'>
          <span class="item-span" @click='toRoute("/scorehistory")'>Score History<img class="mint-header-image" src='@/assets/img/icons/r-arrow.svg'></span>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col @click='dismissModal'>
          <span class="item-span" @click='toRoute("/leaderboard")'>Leaderboard<img class="mint-header-image" src='@/assets/img/icons/r-arrow.svg'></span>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col @click='dismissModal'>
          <span class="item-span" @click='toRoute("/policies")'>Rules & Policies<img class="mint-header-image" src='@/assets/img/icons/r-arrow.svg'></span>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col @click='dismissModal'>
          <span class="item-span" @click='toRoute("/signout")'>Sign Out / Unpair<img class="mint-header-image" src='@/assets/img/icons/r-arrow.svg'></span>
        </ion-col>
      </ion-row> -->
    </ion-grid>
  </ion-content>
</template>

<script>


import BaseHeader from "@/components/BaseHeader";
import LeftButton from "@/components/LeftButton";
import PairedLeftButton from "@/components/PairedLeftButton";
import { getSettings } from "@/use/useSettings";
import { IonCol, IonContent, IonGrid, IonRow } from "@ionic/vue";
import { addIcons } from "ionicons";
import { closeOutline } from "ionicons/icons";
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Settings",
  components: {LeftButton, IonContent, BaseHeader, PairedLeftButton, IonCol, IonRow, IonGrid},
  props: ['title', 'dismiss'],
  setup(props) {
    const router = useRouter();
    addIcons({
      'close-outline': closeOutline
    });
    const settingsMenu=reactive([
      {route:'/tvsettings',label:" TV Settings"},
      {route:'/scorehistory',label:" Score History"},
      {route:'/leaderboard',label:" Leaderboard"},
      {route:'/policies',label:" Rules & Policies"},
      {route:'/signout',label:" Sign Out / Unpair"},
    ])

    const dismissModal = () => {
      router.go(-1)
    }
    const userSettings = getSettings();

    const onSettingsChange = async ($event) => {
      //TODO add code
    };


  const toRoute = (route) => {
    router.push(route);
  }
  // async function opensubModal(a) { // change added
  //   let obj=null;
  //   switch (a) {
  //     case "Tvsettings":
  //       obj= Tvsettings;
  //       break;
  //     case "Scorehistory":
  //       obj= Scorehistory;
  //       break;
  //     case "Leaderboard":
  //       obj= Leaderboard;
  //       break;
  //     case "Policies":
  //       obj= Policies;
  //       break;
  //     case "Signout":
  //       obj= Signout;
  //       break;
    
  //     default:
  //       break;
  //   }


    //console.log(obj)
        //     const modal = await modalController.create({
        //         component: obj,
        //        componentProps: {
        //     title: 'New Title',
        //     dismiss: () => modal.dismiss()
        //   }, // Change added
        //     })
        //     return modal.present()
        // }
    return {
      dismissModal,
      userSettings,
      onSettingsChange,
      toRoute,
      settingsMenu
      // opensubModal
    }
  }
})
</script>

<style scoped>
.code-button {
  display: block;
  margin: 0 auto;
  text-align: center;
  border: 0;
  padding: 0 10px;
  background: transparent;
  font-size: 30px;
  color: var(--ion-color-primary-text);
}

ion-content {
  --background: linear-gradient(180deg, var(--ion-color-active-dark) 0%, var(--ion-color-active-darker) 100%);
}
ion-grid {
    width: 84.615vw;
    margin: 56px auto 0 auto;
    padding: 0;
}
ion-row {
    height: 46px;
    margin-bottom: 10px;
    position: relative;
}
ion-row:after {
  content:"";
  display: block;
  width: 100%;
  border-bottom: 1px solid var(--ion-color-primary-border);
  position: relative;
  opacity: 0.3;
}
ion-row:after:last-child {
    border-bottom: none;
}

ul {
  list-style-type: none;
}

.item-span {
  font-family: 'Industry Black';
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--ion-color-primary-text);
  width: 100%;
  cursor:pointer;
  display: block;
}
.item-span img {
    width: 14px;
    height: 14px;
    text-align: right;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

ion-toggle {
  --background: #F5F5F5;
  --background-checked: var(--ion-color-correct);

  --handle-background: #FFF;
  --handle-background-checked: #FFF;

  height: 18.67px;
  width: 38.33px;
  border-radius: 20.333px;
  --handle-height: 18.67px;
  --handle-width: 15.67px;
}

.second-col {
  justify-self: flex-start;
  align-self: center;
  padding-top: 10px;
}
</style>
