<template>
  <ion-page>

    <Header />
    <ion-content>
      <div class="text-create-div ">
        <span class="pairing-text">CREATE YOUR ACCOUNT</span>
      </div>
      <div class="text-align-centre">
        <form>
          <div class="padding-3">
            <ion-list class="ion-item-border-radius">
              <ion-item class="ion-item-border">
                <ion-input :value="state.email" v-on:ionInput="state.email = $event.target.value" class="input"
                  name="Email Address" type="text" placeholder="Email Address" v-model="email" />
              </ion-item>

              <ion-item class="ion-item-border">
                <ion-input :value="state.password" v-on:ionInput="state.password = $event.target.value" class=""
                  name="Password" type="password" placeholder="Password" />
              </ion-item>
              <ion-item class="ion-item-border">
                <ion-input :value="state.name" v-on:ionInput="state.name = $event.target.value" class="" type="text"
                  placeholder="First Name" />
              </ion-item>
              <ion-item class="ion-item-border">
                <ion-input :value="state.lastname" v-on:ionInput="state.lastname = $event.target.value" class=""
                  name="Last Name" type="text" placeholder="Last Name" />
              </ion-item>
              <ion-item class="ion-item-border">
                <ion-input :value="state.displayname" v-on:ionInput="state.displayname = $event.target.value" class=""
                  name="Display Name" type="text" placeholder="Display Name" />
              </ion-item>

            </ion-list>
            <div class="code-error" v-if="state.error">{{ state.error }}</div>
          </div>

          <button class="code-button code-button-color" type="button" :disabled="!enabledButton" @click="signup">SIGN
            UP</button>
        </form>
      </div>
      <div class="item-span text-align-centre">
        By creating an account, you are agreeing to the <a class="warning-TermsofUse"
          @click="toRoute('/termsofuse')">Terms
          of Use</a> and <a class="warning-Policies" @click="toRoute('/policies')">Policies</a>

      </div>

    </ion-content>
  </ion-page>
</template>
<script>

import { IonContent, IonInput, IonItem, IonList, IonPage } from "@ionic/vue";
//import FDivider from "@/components/Divider";
import useSignUPForm from "@/use/useSignUPForm";
import { chevronBackCircleOutline } from 'ionicons/icons';
import Header from "@/components/Header";


export default {
  name: 'SignUpForm',

  //components: {FDivider, IonPage, Header, IonContent, IonInput, IonList, IonItem, IonLabel},
  components: { IonPage, Header, IonContent, IonInput, IonList, IonItem },
  setup(props) {
    const { state, toRoute, signup, enabledButton } = useSignUPForm(props);
    return { state, toRoute, chevronBackCircleOutline, signup, enabledButton }
  },
}


</script>

<style>
:root {
  --input-order: 15px;
}

ion-item {
  --min-height: 100%;
}
</style>
<style scoped>
.pairing-text {
  font-family: 'Industry Black';
  font-style: normal;
  font-size: 25px;
  line-height: 27px;
  color: #FFFFFF;
}

.warning-TermsofUse {
  color: var(--ion-color-right);
  text-transform: none;
}

a.warning-TermsofUse {
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 0.5px;
}

.warning-Policies {
  color: var(--ion-color-right);
  text-transform: none;
}

a.warning-Policies {
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 0.5px;
}

.text-create-div {
  margin-bottom: 27px;
  margin-top: 31px;
  text-align: center;
}

.description-text {
  font-family: "Proxima Nova Rg";
  font-size: 12px;
  color: rgba(0, 46, 87, 1);
}

.banner-free-image {
  max-width: 90vw;

}

.faceid-image {
  max-width: 50vw;
  max-height: 50vw;
  width: 15vw;
}

.item-span {
  padding: 20px;
  font-family: 'Proxima Nova RG';
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.38px;
  color: var(--ion-color-primary-text);
}

.text-align-centre {
  text-align: center;
}

.margin-0-auto {
  margin: 0 auto;
}

.margin-16-auto {
  margin: 16px auto;
}

.signup-link-div {
  font-size: 15px;
  width: 60vw;
}

.description-div {

  width: 65vw;
  margin: 0 auto;
}

.code-input {
  display: block;
  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px;
  width: 60%;
  color: var(--ion-color-active-dark);
  text-transform: uppercase;
  font-family: Industry;
  font-weight: bold;
}

.code-error {
  padding: 11 px 0;
  height: 70px;
  font-family: 'Proxima Nova RG';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 16px;
  letter-spacing: 0.38px;
  color: var(--ion-color-right);
  width: 100%;
}

.code-button {
  display: block;
  margin: 0 auto;
  padding: 0;
  width: 260px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  font-family: 'Industry Black';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: 0.38px;
  color: #FFFFFF;
}

.code-button-color {
  background: var(--ion-color-active);
  color: #FFFFFF;
}

.login-button-color {
  background-color: transparent;
  color: var(--ion-color-active);
  border: 1px solid var(--ion-color-active);
}



.code-button-color:disabled,
.code-button-color[disabled] {
  opacity: 0.5;
}

.ion-item-border {
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  border: 1px solid rgb(255 255 255 / 50%);
  border-radius: 13px;
  margin-bottom: 10px;
}

.ion-item-border.item-has-focus {
  border: 1px solid rgb(255 255 255 / 100%);
}

.ion-item-border:last-child {
  margin-bottom: 0;
}

.ion-item-border-radius {
  /*border-radius: var(--input-order);*/
  border: none;
  width: 84.615vw;
  margin: 0 auto 68px auto;
  background: transparent;
}

/*.ion-item-border:first-child {
  border-top-right-radius: var(--input-order);
  border-top-left-radius: var(--input-order);
}

.ion-item-border:last-child {
  border-bottom-right-radius: var(--input-order);
  border-bottom-left-radius: var(--input-order);
}*/

.padding-3 {
  padding-left: 3%;
  padding-right: 3%;
}


.color-white {
  color: white;
}

ion-back-button {
  --color: white;
  margin-left: 10px;
}

ion-item {
  --inner-padding-end: 5px;
  --padding-start: 5px;
  --inner-border-width: 0px;

}

ion-item::part(native) {
  background: transparent;
  height: 30px;
}

.native-input.sc-ion-input-ios {
  padding-top: 10px;
}

ion-input {
  opacity: 1;
}

ion-input {
  font-family: "Proxima Nova Rg";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.38px;
  color: #FFFFFF;
}

.label {
  --color: rgba(68, 150, 248, 1) ! important;
  --ion-font-family: "Proxima Nova Rg";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  /* identical to box height */

  letter-spacing: 0.02em;


}

.policy-div {
  color: #FFFFFF;
  margin: 0 4.3vw;
}

.warning-policy {
  color: var(--ion-color-right);
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 0.5px;
}
</style>
