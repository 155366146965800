<template>
  <ion-page>
    <base-header>
      <template v-slot:right>
        <left-button :icon="'close-outline'" @click='$router.go(-1)'></left-button>
      </template>
      <template v-slot:left>
        <PairedLeftButton @click="toRoute('/tvsettings')"></PairedLeftButton>
      </template>
    </base-header>
    <ion-content>
      <div class="text-align-centre login-text">
        <span class="info-text">SCORE HISTORY</span>
      </div>

      <div class="container-items">


        <div class="code-error" v-if="state.serverError">{{ state.serverError }}</div>


        <div class="history-item" v-for="(item) in state.questions" :key="item">
          <div class="history-question">
            <p class="question-icon">Q</p>
            <p class="question-text">{{ item.question }}</p>
          </div>
          <div class="history-answer correct" :class="item.type">
            <p v-if="!item.answerRight && (item.type != 'notresolved')" class="answer-icon"><img
                src='@/assets/img/icons/checkmark.svg'></p>
            <p v-if="item.answerRight" class="answer-icon"><img src='@/assets/img/icons/r-cross.svg'></p>
            <p class="answer-text">{{ item.answerUser }}</p>
          </div>
          <div v-for="(itemanswer) in item.answerRight" :key="itemanswer" class="history-answer wrong"
            :class="item.type">
            <p class="answer-icon">A</p>
            <p class="answer-text">{{ itemanswer }}</p>
          </div>
          <div v-if="!item.answerRight && (item.type != 'notresolved')" class="history-answer total">
            <p class="answer-icon"><img src='@/assets/img/icons/cup.svg'></p>
            <p class="answer-text">{{ item.scoreCorrect }} CORRECT</p>
          </div>
          <div class="history-answer total">
            <p class="answer-icon"><img src='@/assets/img/icons/cup.svg'></p>
            <p class="answer-text">{{ item.scoreParticipation }} PARTICIPATION</p>
          </div>
        </div>
      </div>



    </ion-content>
  </ion-page>
</template>

<script>


import BaseHeader from "@/components/BaseHeader";
import LeftButton from "@/components/LeftButton";
import PairedLeftButton from "@/components/PairedLeftButton";
import useScorehistory from "@/use/useScorehistory";
import { IonContent, IonPage } from "@ionic/vue";
import { addIcons } from "ionicons";
import { arrowBackOutline, closeOutline } from "ionicons/icons";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Scorehistory",
  components: { LeftButton, IonPage, IonContent, BaseHeader, PairedLeftButton },
  props: ['title', 'dismiss'],
  setup(props) {
    const { state, toRoute } = useScorehistory(props);
    addIcons({
      'close-outline': closeOutline,
      'back-outline': arrowBackOutline
    });
    const dismissModal = () => {
      props.dismiss();
    }

    return { state, dismissModal, toRoute }
  }
})
</script>

<style scoped>
.code-button {
  display: block;
  margin: 0 auto;
  text-align: center;
  border: 0;
  padding: 0 10px;
  background: transparent;
  font-size: 30px;
  color: var(--ion-color-primary-text);
}

ion-modal {
  width: 84.615vw !important;
}

ion-content {
  --background: linear-gradient(180deg, var(--ion-color-active-dark) 0%, var(--ion-color-active-darker) 100%);
}

ion-grid {
  width: 84.615vw;
  margin: 56px auto 0 auto;
  padding: 0;
}

ion-row {
  height: 46px;
  margin-bottom: 10px;
  position: relative;
}

ion-row:after {
  content: "";
  display: block;
  width: 100%;
  border-bottom: 1px solid var(--ion-color-primary-border);
  position: relative;
  opacity: 0.3;
}

ion-row:after:last-child {
  border-bottom: none;
}

ul {
  list-style-type: none;
}

ion-toggle {
  --background: #F5F5F5;
  --background-checked: var(--ion-color-correct);

  --handle-background: #FFF;
  --handle-background-checked: #FFF;

  height: 18.67px;
  width: 38.33px;
  border-radius: 20.333px;
  --handle-height: 18.67px;
  --handle-width: 15.67px;
}

.second-col {
  justify-self: flex-start;
  align-self: center;
  padding-top: 10px;
}

.code-error {
  font-family: 'Proxima Nova RG';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  color: var(--ion-color-right);
  position: absolute;
  top: 10%;
  left: 22%;
  transform: translate(-22%, -10%);
}

.info-text {
  font-family: 'Industry Black';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 38px 0 50px 0;
  color: var(--ion-color-primary-text);
}

.container-items {
  width: 84.615vw;
  min-height: 495px;
  position: relative;
  margin: 0 auto;
}

.container-items:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(180deg, var(--ion-color-active) 0%, var(--ion-color-passive) 100%);
  opacity: 0.2;
  filter: var(--ion-filter-dark);
  border-radius: 16px;
  z-index: -1;
}

.history-item {
  display: flex;
  flex-direction: column;
  padding: 0 12px 15px 12px;
}

.history-item:first-child {
  padding: 15px 12px 0 12px;
}

.history-item:after {
  content: "";
  display: block;
  width: 75.897vw;
  border-bottom: 1px solid var(--ion-color-primary-border);
  opacity: 0.3;
  margin: 15px auto 10px auto;
}

.history-question {
  display: flex;
}

.history-question p {
  font-family: 'Proxima Nova Bold';
  font-size: 18px;
  line-height: 23px;
  margin: 0;
}

.history-question p.question-icon {
  margin: 0 7px 0 0;
  color: var(--ion-color-active);
}

.history-question p.question-text {
  color: var(--ion-color-primary-text);
}

.history-answer {
  background: var(--ion-color-active-dark);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  margin: 8px 22px 0 22px;
  padding: 4px 0;
  height: 33px;
  display: flex;
  overflow: hidden;
}

.history-answer.correct.right {
  border: 1px solid var(--ion-color-correct);
}

.history-answer.total {
  background: var(--ion-color-active);
}

.history-answer.right,
.history-answer.wrong.wrong {
  border: 1px solid var(--ion-color-border-right);
}

.history-answer.correct.wrong {
  border: 1px solid var(--ion-color-wrong);
}

.history-answer.answered,
.history-answer.correct.notresolved {
  border: 1px solid var(--ion-color-primary-border);
}

.history-answer p {
  margin: 0;
  text-transform: uppercase;
}

p.answer-icon img {
  margin-left: 18px;
}

.history-answer.correct p.answer-icon img {
  width: 23px;
  min-width: 23px;
  height: 23px;
}

.history-answer.total p.answer-icon img {
  width: 24px;
  height: 21px;
  margin-top: 2px;
}

p.answer-text {
  font-family: 'Industry Black';
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.38px;
  color: var(--ion-color-primary-text);
  width: calc(100% - 41px);
  padding-right: 41px;
}

.history-answer.correct.notresolved p.answer-text {
  margin-left: 0;
  width: 100%;
  padding-right: 0;
}

.history-answer:not(.correct).right .answer-icon {
  background-color: var(--ion-color-bg-right);
  width: 23px;
  min-width: 23px;
  height: 23px;
  border-radius: 50%;
  font-family: 'Industry Black';
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.38px;
  color: var(--ion-color-active-dark);
  margin-left: 18px;
}

.history-answer:not(.correct).wrong .answer-icon {
  background-color: var(--ion-color-right);
  width: 23px;
  min-width: 23px;
  height: 23px;
  border-radius: 50%;
  font-family: 'Industry Black';
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.38px;
  color: var(--ion-color-active-dark);
  margin-left: 18px;
}

.history-answer.answered .answer-text {
  margin-left: 0;
}
</style>
