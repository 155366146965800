<template>
  <ion-page>
    <home-header>
      <template v-slot:left>
        <PairedLeftButton @click="toRoute('/tvsettings')"></PairedLeftButton>
      </template>
    </home-header>

    <ion-content>
      <div class="text-align-centre login-text">
        <span class="info-text">STAY TUNED</span>
      </div>
      <div class="container-items">
        <ion-row class="staytuned-item">
          <ion-col>
            <span class="item-span">NEXT QUESTION<br />COMING SOON</span>
          </ion-col>
        </ion-row>
        <ion-row class="staytuned-item">
          <ion-col>
            <div class="dot-flashing"></div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <button class="refresh-btn" @click="refresh">
              <span v-if="!isLoading">REFRESH</span>
              <div class="lds-ring" v-else>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </button>
          </ion-col>
        </ion-row>
      </div>
    </ion-content>
    <transition name="slideup">
      <div class="refresh-toast" v-if="toastVisible">
        <div class="wrapper">
          <div class="icon">
            <ion-icon name="alert-outline" icon="alert-outline" size="large"></ion-icon>
          </div>

          <h2>No questions available</h2>

        </div>
      </div>
    </transition>
  </ion-page>
</template>
<script>
import HomeHeader from "@/components/HomeHeader";
import PairedLeftButton from "@/components/PairedLeftButton";
import { ref } from "vue";
import router from '@/router';
import useStaytuned from "@/use/useStaytuned";
import { IonCol, IonContent, IonIcon, IonPage, IonRow } from "@ionic/vue";
import { addIcons } from "ionicons";
import { add, alertCircleOutline, close, tvOutline } from "ionicons/icons";
import { BEService } from '@/service/backend.service';
// import { useRouter } from "vue-router";
export default {
  name: "Staytuned",
  //components: {IonPage, IonContent, PairedLeftButton, HomeCarousel, LeagueFilter, FabTicket, HomeHeader},
  components: { IonPage, IonContent, IonCol, IonRow, PairedLeftButton, HomeHeader, IonIcon },
  setup(props) {
    const { state, onClick, toRoute } = useStaytuned(props);
    const isLoading = ref(false)
    const toastVisible = ref(false)

    addIcons({
      "tv-outline": tvOutline,
      add: add,
      "alert-outline": alertCircleOutline,
      close
    });

    function showToast() {
      toastVisible.value = true
      setTimeout(() => {
        toastVisible.value = false
      }, 5000)
      isLoading.value = false
    }

    const refresh = async () => {
      console.log("Refresh Button")
      const beService = new BEService();
      try {
        isLoading.value = true

        const lastQuestion = await beService.getLastQuestion();
        if (!lastQuestion || !lastQuestion?.idQuestion) {
          console.log("No question found")
          showToast();
          return
        }

        if (localStorage.question) {
          const storedQuestion = JSON.parse(localStorage.question)
          if (storedQuestion.idQuestion == lastQuestion.idQuestion) {
            console.log("Question already showed")
            showToast();
            return;
          }
        }

        //The question can be shown
        localStorage.question = JSON.stringify({ ...lastQuestion })
        router.push('/question')

      } catch (err) {
        console.error("error on retrieving the last question", err)
      }
      finally {
        isLoading.value = false
      }

      // 
    }

    return { state, onClick, toRoute, refresh, isLoading, toastVisible };
  },
};
</script>

<style scoped>
background-content {
  --background: #0a233e;
}

ion-content {
  --background: linear-gradient(180deg,
      var(--ion-color-active-dark) 0%,
      var(--ion-color-active-darker) 100%);
}

.info-text {
  font-family: "Industry Black";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 38px 0 50px 0;
  color: var(--ion-color-primary-text);
}

.icon {
  color: var(--ion-color-primary-text);
  background: transparent;
}

/* Q & A */

.container-items {
  width: 84.615vw;
  height: 495px;
  position: relative;
  margin: 38px auto 0 auto;
  padding-top: 42px;
}

.container-items:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(180deg,
      var(--ion-color-active) 0%,
      var(--ion-color-passive) 100%);
  opacity: 0.2;
  filter: var(--ion-filter-dark);
  border-radius: 16px;
  z-index: -1;
}

ion-row {
  margin-bottom: 18px;
}

ion-col {
  margin: 0 34px;
  padding: 0;
  max-width: calc(100% - 68px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-span {
  font-family: "Proxima Nova Bold";
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0;
  color: var(--ion-color-primary-text);
}

/* loading dots */

.dot-flashing {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: rgba(217, 217, 217, 0.8);
  color: rgba(217, 217, 217, 0.8);
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 1s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -23px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: rgba(217, 217, 217, 0.6);
  color: rgba(217, 217, 217, 0.6);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 23px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #d9d9d9;
  color: #d9d9d9;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1.5s;
}

button.refresh-btn {
  font-family: "Industry Black";
  font-weight: 700;
  width: 150px;
  text-align: center;
  background-color: var(--ion-color-active) !important;
  color: var(--ion-color-primary-text);
  font-size: 1rem;
  font-weight: bold;
  border-radius: 50px;
  padding: .5rem 2rem;
  margin-top: 6rem;
}

.refresh-toast {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 2rem;
}

.refresh-toast .wrapper {
  display: flex;

  gap: 2;
  background: var(--ion-color-active);
  color: var(--ion-color-primary-text);
  font-family: "Proxima Nova Rg";
  border-radius: 10px 10px 0 0;
  height: 100%;

  padding: 1rem;
}

.refresh-toast .wrapper h2 {
  margin: 0 !important
}

.refresh-toast .wrapper .icon {
  width: 20%;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 1rem;
  height: 1rem;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 1rem;
  height: 1rem;


  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes dotFlashing {
  0% {
    background-color: #d9d9d9;
  }

  50%,
  100% {
    background-color: rgba(217, 217, 217, 0.6);
  }
}



/* VUE JS TRASITION */
.slideup-enter-active {
  transition: all 0.3s ease-out;
}

.slideup-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slideup-enter-from,
.slideup-leave-to {
  transform: translateY(100px);
  opacity: 0;
}
</style>
