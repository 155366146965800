<template>
  <ion-page>
    <Header />
    <ion-content>
      <div class="signup-link-div  text-align-centre ion-margin-top margin-16-auto">
        <a class="" @click="toRoute('/signup')">NEW TO THIS EXPERIENCE? <span class="warning-signup">Sign Up Now</span></a>
      </div>
      <div class="text-align-centre login-text">
        <span class="pairing-text">LOGIN TO YOUR ACCOUNT</span>
      </div>
      <div class="text-align-centre ion-margin-top">
        <form>
          <div class="padding-3 login-container">
            <ion-list class="ion-item-border-radius">
              <ion-item class="ion-item-border">
                <ion-input :value="state.username" v-on:ionInput="state.username = $event.target.value" class=""
                  type="text" placeholder="Email Address" />
              </ion-item>
              <ion-item class="ion-item-border">
                <ion-input :value="state.password" v-on:ionInput="state.password = $event.target.value" class=""
                  type="password" placeholder="Password" />
              </ion-item>
            </ion-list>
            <ion-label class="error-label" v-if="state.error">{{ state.error }}</ion-label>
            <ion-label v-if="state.isPasswordIncorrect" class="error-label">The email or password you entered<br />is
              incorrect</ion-label>
            <ion-label v-if="state.isGenericError" class="error-label">Generic error</ion-label>

          </div>

          <button class="code-button ion-margin-top" type="button" :disabled="!isButtonValid" @click="login">
            LOGIN
          </button>
        </form>
      </div>
      <div class="forgot-link-div  text-align-centre ion-margin-top margin-16-auto">
        <a class="forgot-link" @click="toRoute('/forgot-password')">Forgot Password?</a>
        <span class="signup-link" @click="toRoute('/signup')">New? <a class="warning-signup">Sign Up</a></span>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>


import Header from "@/components/Header";
import useSignInForm from "@/use/useSignInForm";
import { IonContent, IonInput, IonItem, IonLabel, IonList, IonPage } from "@ionic/vue";



export default {

  name: 'SignInForm',
  components: { IonPage, Header, IonContent, IonInput, IonList, IonItem, IonLabel },
  setup(props) {
    const { state, isButtonValid, login, toRoute } = useSignInForm(props);

    return { state, isButtonValid, login, toRoute }
  }
}
</script>

<style>
:root {
  --input-order: 15px;
  --background: transparent;
}
</style>
<style scoped>
.login-text {
  margin-bottom: 0;
}

.pairing-text {
  font-family: 'Industry Black';
  height: 46px;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 19px;
  color: #FFFFFF;
}

.description-text {
  font-family: "Proxima Nova Rg";
  font-style: normal;
  font-size: 12px;
  color: rgba(0, 46, 87, 1);
}

.banner-free-image {
  width: 340.52px;
  height: 162.4px;

}

.faceid-image {
  max-width: 50vw;
  max-height: 50vw;
  width: 15vw;
}

.text-align-centre {
  text-align: center;
}

.margin-0-auto {
  margin: 0 auto;
}

.margin-16-auto {
  margin: 16px auto;
}

.signup-link-div {
  font-size: 14px;
  width: 100%;
  height: 26px;
  margin-top: 0;
  margin-bottom: 30px;
  background: var(--ion-color-active);
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-link-div a {
  font-family: 'Proxima Nova Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.38px;
  text-transform: uppercase;
  color: var(--ion-color-primary-text);
}

.warning-signup {
  color: var(--ion-color-right);
  text-transform: none;
}

.description-div {

  width: 65vw;
}

.code-input {
  display: block;
  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px;
  width: 60%;
  color: var(--ion-color-active-dark);
  text-transform: uppercase;
  font-family: Industry;
  font-weight: bold;
}

.code-button {
  display: block;
  margin: 0 auto;
  text-align: center;
  border-radius: 13px;
  padding: 10px;
  width: 260px;
  height: 40px;
  background: var(--ion-color-active);
  font-family: 'Industry Black';
  font-size: 20px;
  color: var(--ion-color-primary-text);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}


.code-button:disabled,
.code-button[disabled] {
  opacity: 0.5;
}

/*
.ion-item-border:first-child {
  border-top-right-radius: var(--input-order);
  border-top-left-radius: var(--input-order);
}

.ion-item-border:last-child {
  border-bottom-right-radius: var(--input-order);
  border-bottom-left-radius: var(--input-order);
} */

.padding-3 {
  margin: 12.77px
}


.ion-item-border {
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  border: 1px solid rgb(255 255 255 / 50%);
  border-radius: 13px;
  margin-bottom: 10px;
}

.ion-item-border.item-has-focus {
  border: 1px solid rgb(255 255 255 / 100%);
}

.ion-item-border:last-child {
  margin-bottom: 0;
}

.ion-item-border-radius {
  /*border-radius: var(--input-order);*/
  border: none;
  width: 84.615vw;
  margin: 0 auto 25px auto;
  background: transparent;
}

ion-item {
  --inner-padding-end: 5px;
  --padding-start: 5px;
  --inner-border-width: 0px;

}

ion-item::part(native) {
  background: transparent;
  height: 30px;
}

ion-input {
  opacity: 1;
}

.label {
  --color: rgba(68, 150, 248, 1) ! important;
  --ion-font-family: "Proxima Nova Rg";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  /* identical to box height */

  letter-spacing: 0.02em;
  --padding-bottom: 20px;

}

ion-label.error-label {
  font-family: 'Proxima Nova RG';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 16px;
  letter-spacing: 0.38px;
  color: var(--ion-color-right);
  width: 100%;
}


ion-input {
  font-family: "Proxima Nova Rg";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.38px;
  color: var(--ion-color-primary-text);
}

.forgot-link-div {
  font-family: "Proxima Nova Rg";
}

.forgot-link-div span {
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: var(--ion-color-primary-text);
  line-height: 24px;
  letter-spacing: 0.38px;
  margin-top: 5px;
}

.forgot-link-div a.forgot-link,
.forgot-link-div a.warning-signup {
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 0.5px;
}

.forgot-link-div a.forgot-link {
  color: var(--ion-color-primary-text);
}
</style>
