<template>

  <base-header>
    <template v-slot:right>
      <ion-back-button class="back-button color-white"
                       text="" :icon="chevronBackCircleOutline" mode="ios" v-if="false">
      </ion-back-button>
    </template>
    <template v-slot:left>
      <slot name="left"></slot>
    </template>
  </base-header>
</template>

<script>
import BaseHeader from "@/components/BaseHeader";
import useHeader from "@/use/useHeader";
import { IonBackButton } from "@ionic/vue";
import { chevronBackCircleOutline } from "ionicons/icons";

export default {
  name: 'Header',
  components: {IonBackButton, BaseHeader},
  props: {
    fixed: Boolean,
    title: String
  },
  setup(props) {
    const {state, toRoute} = useHeader(props);
    return {state, toRoute, chevronBackCircleOutline}
  }
};
</script>

<style lang="css">
:root {
  -color-blue: var(--ion-color-active-darkest);

}

.mint-header-image {
  width: 144px;
  height: auto;
}

.header {
  max-height: 53px;
}

ion-header {
  max-height: 53px;
}

.color-white {
  color: white;
}

ion-back-button {
  --color: white;
  --margin-start: 10px;
}

ion-buttons.buttons-first-slot {
    margin-left: 15px;
    align-items: start;
}
</style>
