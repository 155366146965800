<template>
  <ion-page>
    <base-header>
      <template v-slot:right>
         <left-button :icon="'close-outline'" @click='$router.go(-1)'></left-button>
      </template>
      <template v-slot:left>
        <PairedLeftButton></PairedLeftButton>
      </template>
    </base-header>
    <ion-content>
      <div class="text-align-centre login-text">
        <span class="info-text">TV SETTINGS</span>
      </div>


      <ion-grid class="container-items">
        <ion-row class="tvsettings-item">
          <ion-col>
            <span class="item-span">TV BOTTOM BAR</span>
          </ion-col>
          <ion-col class="" size="3" @click="changeData('bar')">
            <ion-toggle id="checkParent" ref="checkParent" :checked="state.toggles.bar" @click="checkEnable()">
            </ion-toggle>
          </ion-col>
        </ion-row>

        <ion-row class="tvsettings-item">
          <ion-col>
            <span class="item-span">Q & A</span>
          </ion-col>
          <ion-col @click="changeData('qa')">
            <ion-toggle id="checkBoxChildren1" ref="checkBoxChildren1" :checked="state.toggles.qa"
              :disabled="!state.toggles.bar">
            </ion-toggle>
          </ion-col>
        </ion-row>
        <ion-row class="tvsettings-item">
          <ion-col>
            <span class="item-span">FANTASY UPDATES</span>
          </ion-col>
          <ion-col class="" size="3" @click="changeData('fantasy')">
            <ion-toggle id="checkBoxChildren2" ref="checkBoxChildren2" :checked="state.toggles.fantasy"
              :disabled="!state.toggles.bar">
            </ion-toggle>
          </ion-col>
        </ion-row>
        <ion-row class="tvsettings-item">
          <ion-col>
            <span class="item-span">PPR SCORING</span>
          </ion-col>
          <ion-col class="" size="3" @click="changeData('ppr')">
            <ion-toggle id="checkBoxChildren3" ref="checkBoxChildren3" :checked="state.toggles.ppr"
              :disabled="!state.toggles.bar">
            </ion-toggle>
          </ion-col>
        </ion-row>
      </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script>


import BaseHeader from "@/components/BaseHeader";
import LeftButton from "@/components/LeftButton";
import PairedLeftButton from "@/components/PairedLeftButton";
import { getSettings } from "@/use/useSettings";
import useTvsettings from "@/use/useTvsettings";
import { IonContent, IonPage, IonToggle } from "@ionic/vue";
import { addIcons } from "ionicons";
import { arrowBackOutline, closeOutline } from "ionicons/icons";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Tvsettings",
  components: { LeftButton, IonPage, IonContent, IonToggle, BaseHeader, PairedLeftButton },
  props: ['title', 'dismiss'],
  setup(props) {

    const { state, toRoute, onClick, checkEnable, closed, changeData } = useTvsettings(props);

    addIcons({
      'close-outline': closeOutline,
      'back-outline': arrowBackOutline
    });


    const dismissModal = () => {
      props.dismiss();
    }
    const userSettings = getSettings();

    return {
      state,
      dismissModal,
      userSettings,
      closed,
      toRoute,
      onClick,
      checkEnable,
      changeData
    }
  }
})
</script>

<style scoped>
.code-button {
  display: block;
  margin: 0 auto;
  text-align: center;
  border: 0;
  padding: 0 10px;
  background: transparent;
  font-size: 30px;
  color: var(--ion-color-primary-text);
}

ion-modal {
  width: 84.615vw !important;
}

ion-content {
  --background: linear-gradient(180deg, var(--ion-color-active-dark) 0%, var(--ion-color-active-darker) 100%);
}

ion-grid {
  width: 84.615vw;
  margin: 56px auto 0 auto;
  padding: 0;
}

ion-row {
  margin-bottom: 10px;
  position: relative;
}

ion-row:after {
  content: "";
  display: block;
  width: 100%;
  border-bottom: 1px solid var(--ion-color-primary-border);
  position: relative;
  opacity: 0.3;
}

ion-row:after:last-child {
  border-bottom: none;
}

ul {
  list-style-type: none;
}

.item-span {
  font-family: 'Proxima Nova Bold';
  font-size: 18px;
  line-height: 23px;
  color: var(--ion-color-primary-text);
}

.item-span img {
  width: 14px;
  height: 14px;
  text-align: right;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

ion-toggle {
  --background: var(--ion-color-settings-false);
  --background-checked: var(--ion-color-settings-true);
  --background-disabled: #555555;

  --handle-background: #FFF;
  --handle-background-checked: #FFF;

  height: 23px;
  width: 56px;
  border-radius: 32px;
  --handle-height: 19px;
  --handle-width: 19px;
}

ion-toggle.toggle-disabled {
  --background: #555555;
  --background-checked: #555555;
  opacity: 1;
}

.second-col {
  justify-self: flex-start;
  align-self: center;
  padding-top: 10px;
}

.info-text {
  font-family: 'Industry Black';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 38px 0 50px 0;
  color: var(--ion-color-primary-text);
}

.container-items {
  width: 84.615vw;
  height: 495px;
  position: relative;
  margin: 0 auto;
  padding-top: 24px;
}

.container-items:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(180deg, var(--ion-color-active) 0%, var(--ion-color-passive) 100%);
  opacity: 0.2;
  filter: var(--ion-filter-dark);
  border-radius: 16px;
  z-index: -1;
}

ion-row:after {
  border-bottom-color: transparent;
}

ion-row:first-child:after {
  border-bottom-color: var(--ion-color-primary-border);
}

.tvsettings-item {
  padding: 0 18px;
  margin: 0 0 21px 0;
}

.tvsettings-item:nth-child(2) {
  margin-top: 26px;
}

.tvsettings-item:first-child {
  margin-top: 0;
}

ion-col:first-child {
  margin-left: 16px;
  padding: 0;
}

.tvsettings-item:first-child ion-col:last-child {
  margin-bottom: 20px;
}

ion-col:last-child {
  height: 23px;
  margin-right: 16px;
  margin-bottom: 0px;
  padding: 0;
  width: 56px !important;
  flex: 0 0 0 !important;
  max-width: 100% !important;
}

#checkBox label {
  background: #002fff;
}
.toggle-color.toggle-checked {
    --background: var(--ion-color-settings-false);
    --background-checked: var(--ion-color-settings-true);
    --background-disabled: #555555;
    --handle-background: #FFF;
    --handle-background-checked: #FFF;
    height: 23px;
    width: 56px;
    border-radius: 32px;
    --handle-height: 19px;
    --handle-width: 19px;
}
.toggle-color.toggle-disabled {
  --background: var(--ion-color-settings-false);
    --background-checked: var(--ion-color-settings-true);
    --background-disabled: #555555;
    --handle-background: #FFF;
    --handle-background-checked: #FFF;
    height: 23px;
    width: 56px;
    border-radius: 32px;
    --handle-height: 19px;
    --handle-width: 19px;
}

</style>
