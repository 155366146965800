import { reactive } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { onMounted } from "@vue/runtime-core";
import { useParams } from "@/router";


export default function useSignUpForm() {

    const router = useRouter();
    const state = reactive({
        options: {
            type: 'loop',
            rewind: true,
            gap: '5px',
            padding: {
                left: '0',
                top: '0'

            },
            fixedWidth: '100vw',
            fixedHeight: '156.33px',
            arrows: false,
            autoplay: false
        }



    })

    const toRoute = (route) => {
        router.push(route);
    };

    onMounted(async () => {
        const params = useParams().value;
        //Insert here start command
    });
    return { state, toRoute };
}