import { reactive } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { onMounted } from "@vue/runtime-core";
import { BEService } from '@/service/backend.service';
import { AuthService } from '@/service/auth.service';


export default function useScorehistory() {
    const router = useRouter();
    const state = reactive({
        questions: [],
        serverError: "",

    });

    const toRoute = (route) => {
        router.push(route);
    };


    onMounted(async () => {
        const beService = new BEService();
        const auth = new AuthService();
        try {
            const history = await beService.getHistory(auth.getCurrentUsername());
            if (history) {
                state.questions = history
            }
        } catch (error) {
            console.log(error);
            state.serverError = 'Error on retrieving data';
        }
    });
    return { state, toRoute };
}
