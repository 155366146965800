<template>
  <ion-header>
    <ion-toolbar class="header">
      <ion-buttons slot="start">
        <slot name="right"></slot>
      </ion-buttons>
      <ion-title><img class="mint-header-image" src='@/assets/logo-s6.svg'></ion-title>
      <ion-buttons slot="end">

        <slot name="left"></slot>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import useHeader from "@/use/useHeader";
import { IonButtons, IonHeader, IonTitle, IonToolbar } from "@ionic/vue";
import { chevronBackCircleOutline } from "ionicons/icons";



export default {
  name: 'base-header',
  components: { IonHeader, IonToolbar, IonTitle, IonButtons },
  props: {
    fixed: Boolean,
    title: String
  },
  setup(props) {
    const {state, toRoute} = useHeader(props);

    return {state, toRoute, chevronBackCircleOutline}
  }
};
</script>

<style lang="css">
:root {
  -color-blue: var(--ion-color-active-darkest);

}
:host {
  --background: none;
}
.header {
  --background: var(--ion-bg-header);
  max-height: 53px;
}

ion-header {
  max-height: 53px;
  background-image: linear-gradient(180deg, var(--ion-color-active-dark) 0%, var(--ion-color-active-darker) 100%);
}

.color-white{
  color: var(ion-color-primary-contrast);
}

ion-back-button{
  --color: var(ion-color-primary-contrast);
  --margin-start: 10px;
}
</style>
