<template>
  <ion-page>
    <base-header>
      <template v-slot:right>
        <left-button :icon="'close-outline'" @click='$router.go(-1)'></left-button>
      </template>
      <template v-slot:left>
        <PairedLeftButton @click="toRoute('/tvsettings')"></PairedLeftButton>
      </template>
    </base-header>
    <ion-content>
        <div class="container-items">
          <ion-row class="signout-item">
            <ion-col>
              <span class="item-span">ARE YOU SURE YOU WANT TO SIGN OUT AND UNPAIR?</span>
            </ion-col>
          </ion-row>
          <ion-row class="signout-item">
            <ion-col>
              <button class="code-button code-button-color ion-margin-top" type="button" @click="signout()">YES, SIGN OUT / UNPAIR</button>
            </ion-col>
          </ion-row>
          <ion-row class="signout-item">
            <ion-col>
              <button class="code-button code-button-color ion-margin-top" type="button" @click='toRoute("/home")'>CANCEL</button>
            </ion-col>
          </ion-row>
        </div>

    </ion-content>
  </ion-page>
</template>

<script>


import BaseHeader from "@/components/BaseHeader";
import LeftButton from "@/components/LeftButton";
import PairedLeftButton from "@/components/PairedLeftButton";
import useSignout from "@/use/useSignout";
import { IonCol, IonContent, IonPage, IonRow } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Signout",
  components: {LeftButton, IonPage, IonCol, IonContent, IonRow, BaseHeader, PairedLeftButton},
  props: ['title', 'dismiss'],
  setup(props) {
    const {state, toRoute, signout, dismissModal } = useSignout(props);

    return {state, toRoute, signout, dismissModal}
  }
})
</script>

<style scoped>
.code-button {
  display: block;
  margin: 0 auto;
  text-align: center;
  border: 0;
  padding: 0 10px;
  background: transparent;
  font-size: 30px;
  color: var(--ion-color-primary-text);
}
.signout-item .code-button {
  display: block;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 13px;
    font-family: 'Industry Black';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0.38px;
    color: var(--ion-color-primary-text);
}
.code-button-color {
  background: var(--ion-color-active);
  color: var(--ion-color-primary-text);
}
ion-modal {
  width: 84.615vw !important;
}

ion-content {
  --background: linear-gradient(180deg, var(--ion-color-active-dark) 0%, var(--ion-color-active-darker) 100%);
}
ion-grid {
    width: 84.615vw;
    margin: 56px auto 0 auto;
    padding: 0;
}
ion-row {
    margin-bottom: 10px;
    position: relative;
}
ion-row:first-child {
    margin-bottom: 56px;
}
.container-items {
  width: 84.615vw;
  height: 495px;
  position: relative;
  margin: 46px auto 0 auto;
  padding-top: 24px;
}
.container-items:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(180deg, var(--ion-color-active) 0%, var( --ion-color-passive) 100%);
  opacity: 0.2;
  filter: var(--ion-filter-dark);
  border-radius: 16px;
  z-index: -1;
}
ion-col {
  margin: 0 34px;
  padding: 0;
  max-width: calc(100% - 68px);
  display: flex;
}
.item-span {
  font-family: 'Proxima Nova Bold';
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.38px;
  color: var(--ion-color-primary-text);
}
  
</style>
