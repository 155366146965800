import {useRouter} from "vue-router";

export default function useNotfound() {
    const router = useRouter();

    const toRoute = (route) => {
        router.push(route);
    };


    return {toRoute };
}
