<template>
  <ion-page>
    <home-header>
      <template v-slot:left>
        <PairedLeftButton @click="toRoute('/tvsettings')"></PairedLeftButton>
      </template>
    </home-header>

    <ion-content>
      <div class="text-align-centre login-text">
        <span class="info-text">GAME PLAY STARTS<br />AT KICK OFF!</span>
      </div>

      <div v-if="state.loaded" class="container margin-0-auto">
        <div class="date-box">
          <p>{{ state.date }}</p>
          <p>{{ state.time }}</p>
        </div>
        <div class="teams-box">
          <div class="teams-item">
            <p class="teams-logo"><img v-bind:src='state.away.logo'></p>
            <p class="teams-name">{{ state.away.alias }}</p>
            <p class="teams-place">{{ state.away.lable }}</p>
          </div>
          <div class="teams-item">
            <p class="teams-logo"><img v-bind:src='state.home.logo'></p>
            <p class="teams-name">{{ state.home.alias }}</p>
            <p class="teams-place">{{ state.home.lable }}</p>
          </div>
        </div>
      </div>

    </ion-content>

  </ion-page>
</template>
<script>

import PairedLeftButton from "@/components/PairedLeftButton";
import useHome from "@/use/useHome";
import { IonContent, IonPage } from "@ionic/vue";
import { addIcons } from 'ionicons';
import { add, tvOutline } from 'ionicons/icons';


import HomeHeader from "@/components/HomeHeader";

export default {
  name: 'HOME',
  //components: {IonPage, IonContent, PairedLeftButton, HomeCarousel, LeagueFilter, FabTicket, HomeHeader},
  components: { IonPage, IonContent, PairedLeftButton, HomeHeader },
  setup(props) {
    const { state, toRoute } = useHome(props);

    addIcons({
      'tv-outline': tvOutline,
      'add': add
    });

    return { state, toRoute }
  },

}
</script>


<style scoped>
background-content {

  --background: #0a233e;
}

ion-content {
  --background: linear-gradient(180deg, var(--ion-color-active-dark) 0%, var(--ion-color-active-darker) 100%);
}

.info-text {
  font-family: 'Industry Black';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 34px 0 44px 0;
  color: var(--ion-color-primary-text);
}

.container {
  width: 84.615vw;
  height: 495px;
  position: relative;
}

.container:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(180deg, var(--ion-color-active) 0%, var(--ion-color-passive) 100%);
  opacity: 0.2;
  filter: var(--ion-filter-dark);
  border-radius: 16px;
}

.container:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  border-left: 1px solid var(--ion-color-primary-text);
  transform: translateX(-50%);
  opacity: 0.3;
}

.date-box {
  color: var(--ion-color-primary-text);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px 10px 20px;
  position: relative;
}

.date-box:before {
  content: "";
  display: block;
  width: 100%;
  border-bottom: 1px solid var(--ion-color-primary-text);
  position: absolute;
  bottom: 0;
  opacity: 0.3;
}

.date-box p {
  width: 50%;
  font-family: 'Industry Black';
  font-size: 20px;
  line-height: 43px;
  height: 45px;
  display: flex;
  justify-content: center;
  text-align: center;
  color: var(--ion-color-primary-text);
  margin: 0;
}

.teams-box {
  color: var(--ion-color-primary-text);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  position: relative;
}

.teams-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.teams-item p {
  margin: 0;
  text-transform: uppercase;
}

.teams-item p.teams-logo {
  margin-bottom: 12px;
}

.teams-name {
  margin: 0;
  font-family: 'Industry Black';
  font-size: 20px;
  line-height: 26px;
  color: var(--ion-color-primary-text);
}

p.teams-place {
  font-family: 'Proxima Nova Rg';
  font-size: 12px;
  line-height: 16px;
}

.teams-logo img {
  width: 86px;
  height: 86px;
}

.margin-0-auto {
  margin: 0 auto;
}

.icon {
  color: var(--ion-color-primary-text);
  background: transparent;
}
</style>
