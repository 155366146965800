<template>
  <ion-page>

    <!--    <Header fixed title="">-->
    <!--    </Header>-->
    <Header />
    <ion-content>
      <div class="text-align-centre ion-margin-top">
        <span class="pairing-text">PAIR YOUR DEVICE</span>
      </div>
      <div class="description-div margin-0-auto text-align-centre">
        <span class="description-text">Enter the activation code displayed by your TV below:</span>
      </div>
      <div class="text-align-centre ion-margin-top">
        <form>
          <ion-label v-if="!state.valid" class="error-label">The code entered is invalid. Please try again.</ion-label>
          <input v-model="state.code" class="code-input ion-margin-top" type="text" @input="state.valid = true"
            :class="{ 'error-input': !state.valid }" />
          <button class="code-button ion-margin-top" type="button" :disabled="!state.code" @click="buttonClick">
            CONTINUE
          </button>
        </form>
      </div>
      <div class="text-align-centre ion-margin-top">
        <span>{{ deviceId }}</span>
      </div>

    </ion-content>


  </ion-page>
</template>
<script>

import useCodeForm from "@/use/useCodeForm";
import { IonPage } from "@ionic/vue";
import { ref } from "@vue/reactivity";
import Header from "@/components/Header";

export default {
  name: 'CodeForm',
  components: { Header, IonPage },
  setup(props) {

    const { state, buttonClick } = useCodeForm(props);
    const deviceId = ref(null);
    return { state, deviceId, buttonClick }
  }
}
</script>


<style scoped>
.ion-margin-top {
  margin-top: 26px;
  margin-bottom: 22px;
}

.pairing-text {
  font-family: 'Industry Black';
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 27px;
  color: #FFFFFF;
}

.description-text {
  font-family: Proxima Nova Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: var(--ion-color-primary-text);
}

.text-align-centre {
  text-align: center;
}

.margin-0-auto {
  margin: 0 auto;
}


.description-div {

  width: 75vw;
}

form {
  position: relative;
}

.code-input {
  display: block;
  margin: 42px auto 10px auto;
  text-align: center;
  border: 1px solid var(--ion-color-primary-border);
  box-sizing: border-box;
  border-radius: 16px;
  padding: 10px;
  width: 260px;
  height: 80px;
  color: var(--ion-color-primary-text);
  text-transform: uppercase;
  font-family: 'Industry Black';
  font-size: 30px;
  line-height: 27px;
  background: transparent;
}

.code-input:focus-visible {
  outline: 2px solid var(--ion-color-active);
}

.code-button {
  display: block;
  margin: 0 auto;
  text-align: center;
  border: none;
  box-sizing: border-box;
  border-radius: 13px;
  padding: 10px;
  width: 260px;
  height: 40px;
  background: var(--ion-color-active);
  font-family: 'Industry Black';
  font-size: 1.2rem;
  color: var(--ion-color-primary-text);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}


.code-button:disabled,
.code-button[disabled] {
  background: var(--ion-color-active);
}

.error-label {
  width: 100%;
  font-family: Proxima Nova Rg;
  font-style: normal;
  font-size: 15px;
  color: var(--ion-color-right);
  position: absolute;
  top: -32px;
  left: 50%;
  transform: translateX(-50%);
}

.error-input {
  /*border-color: red;*/
}
</style>
