

import { IonApp } from '@ionic/vue';
import { defineComponent, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    // IonRouterOutlet
  },
  setup() {
    const router = useRouter()
    const app = getCurrentInstance()
    const socket = app?.appContext.config.globalProperties.$websocket;
    if (socket != undefined) {
      console.log("App - Socket OK")
    }
    socket.on('maineventstatus', (data: { status: string }) => {
      if (data.status === "inprogress") {
        console.log('%cMain Event detected', "font-size:1.2rem; color:lightgreen", data)
        router.push("/staytuned");
      }
      if (data.status === "scheduled") {
        router.push("/home");
      }
    })
    socket.on('question',  (data: any) => {
      console.log("[APP] Socket question")
      localStorage.question = JSON.stringify({ ...data })
      localStorage.timer = 60;
      console.log("[APP] Socket question. Timer:  " + localStorage.timer);
       router.replace('/question');
      // router.go(0);

    })

  }
});
