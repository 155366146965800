/* eslint-disable @typescript-eslint/no-this-alias */
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import { BEService } from './backend.service';
import { ErrorType, User } from './user.model';

const POOL_DATA = {
    UserPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
    ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
};

export class AuthService {
    registeredUser: CognitoUser | undefined;
    userPool: CognitoUserPool;
    constructor() {
        this.userPool = new CognitoUserPool(POOL_DATA);
    }
    signUp(email: string, password: string, firstName: string, lastName: string, displayName: string): Promise<string> {
        const promise = new Promise<string>((resolve, reject) => {
            const user: User = {
                email: email,
                password: password,
                firstName: firstName,
                lastName: lastName,
                displayName: displayName
            };

            const attrList: CognitoUserAttribute[] = [];

            attrList.push(new CognitoUserAttribute({
                Name: 'email',
                Value: user.email
            }));
            attrList.push(new CognitoUserAttribute({
                Name: 'family_name',
                Value: user.lastName
            }));
            attrList.push(new CognitoUserAttribute({
                Name: 'given_name',
                Value: user.firstName
            }));
            attrList.push(new CognitoUserAttribute({
                Name: 'name',
                Value: user.displayName
            }));
            const that = this;
            this.userPool?.signUp(user.email, user.password, attrList, [], (err, result) => {
                if (err) {
                    console.log("Name:" + err.name);
                    console.log("Description: " + err.message);
                    if (err.name == 'UsernameExistsException') {
                        reject(ErrorType.EmailExists);
                    }
                    else if (err.name == 'InvalidPasswordException') {
                        reject(ErrorType.PasswordNotConform);
                    }
                    else if (err.name == 'InvalidParameterException') {
                        reject(ErrorType.InvalidPassword);
                    } else {
                        reject(err.message);
                    }
                    return;


                }
                that.registeredUser = result?.user;
                resolve("");
            });
        });

        return promise;
    }

    signIn(username: string, password: string): Promise<string> {

        const promise = new Promise<string>((resolve, reject) => {

            const authData = {
                Username: username,
                Password: password
            };
            const authDetails = new AuthenticationDetails(authData);
            const userData = {
                Username: username,
                Pool: this.userPool
            }
            const cognitoUser = new CognitoUser(userData);


            cognitoUser.authenticateUser(authDetails, {
                onSuccess(result: CognitoUserSession) {

                    console.log(result);
                    const be = new BEService();
                    try {

                        be.addUser(result.getIdToken().getJwtToken());
                        resolve("");
                    } catch (error) {

                        let message;
                        if (error instanceof Error) message = error.message
                        reject(message);
                    }

                },
                onFailure(err) {
                    console.log("Name:" + err.name);
                    console.log("Description: " + err.message);
                    console.error(err);
                    if (err.code == 'NotAuthorizedException') {
                        reject(ErrorType.WrongUserPwd);
                    } else if (err.code == 'UserNotFoundException') {
                        reject(ErrorType.UserNotFound);
                    } else {
                        reject(err.message);
                    }

                }
            })
        });


        return promise

    }
    getAuthenticatedUser() {
        return this.userPool.getCurrentUser();
    }

    async logout() {
        const authUser = this.getAuthenticatedUser();
        const userID = authUser?.getUsername();
        if (authUser != undefined) {
            authUser.signOut();
            if (userID != undefined) {
                const be = new BEService();
                try {
                    await be.deleteUser(userID);
                } catch (error) {
                    let message;
                    if (error instanceof Error) message = error.message
                    console.error(message);
                }
            }
        }
    }

    recoverPasswordSendCode(email: string) {
        //based on this: https://www.youtube.com/watch?v=c3zZwuJ6nys
        const promise = new Promise<string>((resolve, reject) => {
            const userData = {
                Username: email,
                Pool: this.userPool
            }
            const cognitoUser = new CognitoUser(userData);
            cognitoUser.forgotPassword({
                onSuccess(data) {
                    console.log("onSuccess sendCode")
                },
                onFailure(err) {
                    console.error("onFailure sendCode")
                    reject(err);
                },
                inputVerificationCode(data) {
                    console.log("inputVerificationCode sendCode")
                    resolve(data);
                },
            })
        })
        return promise;
    }

    recoverPasswordReset(email: string, code: string, password: string) {
        const userData = {
            Username: email,
            Pool: this.userPool
        }
        return new Promise<string>((resolve, reject) => {
            const cognitoUser = new CognitoUser(userData);
            cognitoUser.confirmPassword(code, password, {
                onSuccess(data) {
                    resolve(data);
                },
                onFailure(err) {
                    reject(err);
                }
            });
        });
    }

    isAuthenticated(): Promise<boolean> {
        const user = this.getAuthenticatedUser();
        return new Promise((resolve, reject) => {

            if (user == undefined || user === null) {
                //reject(new Error('user object not defined '));
                resolve(false)

            }
            user?.getSession((err: Error, session: any) => {
                if (err) {
                    reject(new Error('Failure getting Cognito session: ' + err));
                    return
                } else {
                    if (session.isValid()) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }
            })
        })
    }

    async pairDevice(code: string) {
        const be = new BEService();
        await be.pairDevice(this.userPool.getCurrentUser()?.getUsername(), code);
    }

    getCurrentUsername() {
        return this.userPool.getCurrentUser()?.getUsername();
    }
}
