import { createApp } from 'vue';

import { io } from "socket.io-client"
import App from './App.vue';
import router from './router';

import { IonicVue } from '@ionic/vue';
// @ts-ignore
import { Storage as lStorage } from './plugins/storage.js';
// @ts-ignore
import VueSplide from '@splidejs/vue-splide';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/display.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';

import '@splidejs/splide/dist/css/themes/splide-default.min.css';

/* Theme variables */
import './theme/variables.css';

import { adjectives, animals, colors, uniqueNamesGenerator } from 'unique-names-generator';


const app = createApp(App as any)
    .use(IonicVue, {
        mode: 'ios',
        ignoredElements: [/^ion-/]
    })
    .use(router)
    .use(VueSplide);

    if (!app.config.globalProperties.$websocket){
        const socket = io(process.env.VUE_APP_SOKET);
        app.config.globalProperties.$websocket = socket;
        
        socket.on('connect', () => {
            console.log('%cSocket connected', "font-size:1.2rem; color:tomato", socket.id)
        })
        socket.on('disconnect', () => {
            console.log("Disconnected")
          })
    }


const randomName = uniqueNamesGenerator({ dictionaries: [adjectives, colors, animals] });
if (!lStorage.getItem("deviceName")) {
    lStorage.setItem("deviceName", randomName);
}
const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
if (!lStorage.getItem("uuid")) {
    const uuid = uuidv4();
    lStorage.setItem("uuid", uuid);
}

router.isReady().then(() => {

    app.mount('#app');

});
