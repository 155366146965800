import { Security } from '@/service/security';
import http from '../plugins/http';
import { Toggles } from './user.model';

export class BEService {
    security: Security;
    constructor() {
        this.security = new Security();
    }

    async setTemporaryToken() {
        const userID = Math.floor(Math.random() * 100000000) + 1;
        const APIName = "/token";
        try {
            const param = {
                "userID": userID.toString()
            }
            const resp = await http.post(APIName, param);
            if (!resp) {
                console.error(`${APIName} -  empty response`);
            }
            if (resp.data.error) {
                console.error(`${APIName} API - Error: ${resp.data.errorMessage}`);
            }
            
            this.security.saveToken(resp.data.data.accessToken);
        } catch (error) {
            console.error(error);
        }

    }

    async addUser(idToken: string) {
        const APIName = "/user";
        try {

            await this.setTemporaryToken();
            console.log("temp token set")

            const pairingCode = sessionStorage.getItem("pairingCode");
            let param = {};
            if (!pairingCode) {
                param = {
                    "cognitoIdToken": idToken,
                    "pairingCode": ""
                }
            } else {
                param = {
                    "cognitoIdToken": idToken,
                    "pairingCode": pairingCode
                }
            }
            const resp = await http.post(APIName, param);
            if (!resp) {
                console.error(`${APIName} -  empty response`);
            }
            if (resp.data.error) {
                throw new Error(`${APIName} API - Error: ${resp.data.errorMessage}`);
            }

            this.security.saveToken(resp.data.data.accessToken);
            console.log("Saved logged token");
            sessionStorage.removeItem("pairingCode");
        } catch (error) {
            console.error(error);
        }
    }

    async updateUser(userID: string, toggles: Toggles) {
        try {
            const APIName = "/user";
            const resp = await http.put(APIName, {
                "userID": userID,
                "toggles": {
                    "bar": toggles.bar,
                    "qa": toggles.qa,
                    "fantasy": toggles.fantasy,
                    "ppr": toggles.ppr,
                    "insights": toggles.insights
                }
            });
            if (!resp) {
                console.error(`${APIName} - empty response`);
            }
            if (resp.data.error) {
                throw new Error(`${APIName} API - Error: ${resp.data.errorMessage}`);
            }
        } catch (error) {
            console.error(error);
        }
    }

    async pairDevice(userID: string | undefined, code: string) {
        try {
            if (userID == undefined) {
                throw new Error(`userID not defined`);
            }
            const APIName = "/pair";
            const resp = await http.post(APIName, {
                "userID": userID,
                "code": code
            });
            if (!resp) {
                console.error(`${APIName} -  empty response`);
            }
            if (resp.data.error) {
                throw new Error(`${APIName} API - Error: ${resp.data.errorMessage}`);
            }

        } catch (error) {
            console.error(error);
        }
    }

    async checkCode(code: string) {
        try {
            if (code == undefined) {
                throw new Error(`code not defined`);
            }

            await this.setTemporaryToken();

            const APIName = "/checkPairingCode";
            const resp = await http.get(`${APIName}?code=${code}`);
            if (!resp) {
                console.error(`${APIName} -  empty response`);
            }
            if (resp.data.error) {
                throw new Error(`${APIName} API - Error: ${resp.data.errorMessage}`);
            }

            return resp.data.data.codeExists;
        } catch (error) {
            console.error(error);
        }
    }


    async deleteUser(userID: string) {
        try {
            if (userID == undefined) {
                throw new Error(`userID not defined`);
            }
            const APIName = `/user?userid=${userID}`;
            const resp = await http.delete(APIName);
            if (!resp) {
                console.error(`${APIName} -  empty response`);
            }
            if (resp.data.error) {
                throw new Error(`${APIName} API - Error: ${resp.data.errorMessage}`);
            }

        } catch (error) {
            console.error(error);
        }
    }

    async getMainEvent() {
        try {
            const APIName = "/mainevent";
            const resp = await http.get(APIName);
            if (!resp) {
                console.error(`${APIName} -  empty response`);
                throw new Error(`${APIName} API - Error: Empty response`);
            }
            if (resp.data.error) {
                throw new Error(`${APIName} API - Error: ${resp.data.errorMessage}`);
            }
            return resp.data.data;
        } catch (error) {
            console.error(error);
            throw new Error();
            
        }
    }

    async getLastQuestion() {
        try {
            const APIName = "/lastquestion";
            const resp = await http.get(APIName);
            if (!resp) {
                console.error(`${APIName} -  empty response`);
                throw new Error(`${APIName} API - Error: Empty response`);
            }
            if (resp.data.error) {
                throw new Error(`${APIName} API - Error: ${resp.data.errorMessage}`);
            }
            return resp.data.data;
        } catch (error) {
            console.error(error);
            throw new Error();
            
        }
    }

    async setGameAnswer(userID: string, questionID: string, questionText: string, answerText: string) {
        const APIName = "/gameuseranswer";
        try {

            const param = {
                "userID": userID,
                "questionID": questionID.toString(),
                "questionText": questionText,
                "answerText": answerText,
            }
            console.log("setGameAnswer()", param);
            const resp = await http.post(APIName, param);
            if (!resp) {
                console.error(`${APIName} -  empty response`);
            }
            if (resp.data.error) {
                throw new Error(`${APIName} API - Error: ${resp.data.errorMessage}`);
            }

            return resp;
        } catch (error) {
            console.error(error);
        }
    }


    async getHistory(userID: string) {
        console.log("getHistory")

        let APIName = "/scorehistory?userID=";
        APIName = APIName + userID;
        let resp = null;
        try {
            resp = await http.get(APIName);
            if (!resp) {
                console.error(`${APIName} -  empty response`);
                return;
            }
            if (resp.data.error) {
                console.error(`${APIName} API - Error: ${resp.data.errorMessage}`);
                return;
            }
        } catch (error) {
            const {message}= error as Error
            console.error(message);
            return;
        }
        return resp.data.data;

    }


    async getLeaderboard(userID: string) {
        try {
            let APIName = "/leaderboard?userID=";
            APIName = APIName + userID

            const resp = await http.get(APIName);
            if (!resp) {
                console.error(`${APIName} -  empty response`);
                throw new Error(`${APIName} API - Error: Empty response`);
            }
            if (resp.data.error) {
                throw new Error(`${APIName} API - Error: ${resp.data.errorMessage}`);
            }
            return resp;
        } catch (error) {
            console.error(error);
        }
    }


    async getSetting(userID: string) {
        try {
            let APIName = "/user?userid=";
            APIName = APIName + userID

            const resp = await http.get(APIName);
            if (!resp) {
                console.error(`${APIName} -  empty response`);
                throw new Error(`${APIName} API - Error: Empty response`);
            }
            if (resp.data.error) {
                throw new Error(`${APIName} API - Error: ${resp.data.errorMessage}`);
            }
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    async updateSetting(userID: string, toggles: any) {
        const APIName = "/user";
        try {

            const param = {
                "userID": userID,
                "toggles": toggles,
            }
            console.log("setGameAnswer()", param);
            const resp = await http.put(APIName, param);
            if (!resp) {
                console.error(`${APIName} -  empty response`);
            }
            if (resp.data.error) {
                throw new Error(`${APIName} API - Error: ${resp.data.errorMessage}`);
            }

            return resp;
        } catch (error) {
            console.error(error);
        }
    }
}
