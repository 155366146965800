import { computed, reactive } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { AuthService } from '@/service/auth.service';
import { ErrorType } from '@/service/user.model';


export default function useSignUpForm() {

    const router = useRouter();
    const state = reactive({

        password: "",
        name: "",
        lastname: "",
        displayname: "",
        email: "",
        error: "",


    })
        ;

    const toRoute = (route) => {
        router.push(route);
    };


    const checkEmail = (email) => {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return null;
        } else {
            return 'Invalid Email Address';
        }
    }

    const signup = () => {
        state.error = checkEmail(state.email);


        if (!state.error) {

            const auth = new AuthService();
            auth.signUp(state.email, state.password, state.name, state.lastname, state.displayname)
                .then(() => {
                    toRoute('/VerificationLink');
                })
                .catch((err) => {
                    if (err === ErrorType.EmailExists) {
                        state.error = 'This email is already registered to an existing account';
                    }
                    else if (err === ErrorType.PasswordNotConform) {
                        state.error = 'Password did not conform with policy ';
                    }
                    else if (err === ErrorType.InvalidPassword) {
                        state.error = 'Invalid Password ';
                    }
                });
        }
    }

    const enabledButton = computed(() => {
        return !!state.email && !!state.password && !!state.name && !!state.lastname && !!state.displayname;
    });
    return { state, enabledButton, signup, toRoute };
}
