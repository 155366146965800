import { reactive } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { onMounted } from "@vue/runtime-core";
import { BEService } from '@/service/backend.service';
import { AuthService } from '@/service/auth.service';



export default function useLeaderboard() {
    const router = useRouter();
    const state = reactive({
        currentscore: "",
        leaderboard: [],
        serverError: "",
    });

    const toRoute = (route) => {
        router.push(route);
    };

    onMounted(async () => {
        //TODO add logic to load the state
        const beService = new BEService();
        const auth = new AuthService();
        try {
            const mainEvent = await beService.getLeaderboard(auth.getCurrentUsername());
            if (mainEvent && mainEvent?.data?.data?.leaderboard) {
                state.leaderboard = mainEvent?.data?.data.leaderboard
                state.currentscore = mainEvent?.data?.data.currentscore
            }
        } catch (error) {
            console.log(error);
            state.serverError = 'Error on retrieving data';
        }
    });
    return { state, toRoute };
}
