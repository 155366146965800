<template>
    <ion-page>

        <Header />
        <ion-content>
            <div class="text-align-centre login-text">
                <img class="img-checkmark" src=@/assets/img/icons/checkmark.svg />
            </div>

            <div>
                <ion-row>
                    <ion-col>
                        <div class="info-text-verif-page">WE HAVE EMAILED YOU A VERIFICATION CODE TO RESET YOUR PASSWORD
                        </div>
                    </ion-col>
                </ion-row>
            </div>
            <button class="code-button code-button-color ion-margin-top" type="button"
                @click="toRoute('/forgot-password-new-password')">CONTINUE</button>
        </ion-content>
    </ion-page>
</template>
<script>



//import { chevronBackCircleOutline } from 'ionicons/icons';
import Header from "@/components/Header";
import { IonPage, IonContent } from "@ionic/vue";
import useFPverificationpage from "@/use/useFPverificationpage";


export default {
    name: 'Verificationpage',

    components: { IonPage, Header, IonContent },
    setup(props) {
        const { state, toRoute, signup } = useFPverificationpage(props);
        return { state, toRoute, signup }
    },
}


</script>
  
<style scoped>
.code-button {
    display: block;
    margin: 0 auto;
    padding: 0px;
    width: 260px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 13px;
    font-family: 'Industry Black';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0.38px;

    color: var(--ion-color-primary-contrast);
}

.code-button-color {
    background: var(--ion-color-active);
    color: var(--ion-color-primary-contrast);
}

ion-modal {
    width: 84.615vw !important;
}

ion-content {
    --background: linear-gradient(180deg, var(--ion-color-active-dark) 0%, var(--ion-color-active-darker) 100%);
}

ion-grid {
    width: 84.615vw;
    margin: 56px auto 0 auto;
    padding: 0;
}

ion-row {
    margin-bottom: 10px;
    position: relative;
}

.info-text-verif-page {
    font-family: 'Industry Black';
    font-weight: 700;
    font-size: 25px;
    line-height: 27px;
    display: flex;
    color: var(--ion-color-primary-text);
    text-align: center;
    text-transform: uppercase;
    margin: 0 auto;
    padding: 10%;

}

.img-checkmark {
    display: block;
    margin: 0 auto;
    padding: 0;
    width: 50px;
    margin-top: 6%;
}

.clickhere-link {
    color: yellow;
    text-decoration: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 0.5px;
}

.container-items {
    width: 84.615vw;
    height: 495px;
    position: relative;
    margin: 0 auto;
    padding-top: 24px;
}

.container-items:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(180deg, var(--ion-color-active) 0%, var(--ion-color-passive) 100%);
    opacity: 0.2;
    filter: var(--ion-filter-dark);
    border-radius: 16px;
    z-index: -1;
}

ion-col {
    margin: 0 16px;
    padding: 0;
    max-width: calc(100% - 32px);
    display: flex;
}

.item-span {
    padding: 30px;
    font-family: 'Proxima Nova RG';
    font-size: 17px;
    line-height: 24px;
    letter-spacing: 0.38px;
    color: var(--ion-color-primary-text);
}
</style>
