import { AxiosInstance } from "axios";

export class Security {

  saveToken(token: string) {
    localStorage.setItem("token", token);
  }
  getToken() {
    const token = localStorage.getItem("token") || "";
    return token;
  }

  async getRefreshedToken(axiosApiInstance: AxiosInstance, oldToken: string, userID: string) {
    const APIName = "/refreshToken";
    try {
      const param = {
        "userID": userID,
        "oldToken": oldToken
      }
      console.log("refreshToken()", param);
      const resp = await axiosApiInstance.post(APIName, param);
      if (!resp) {
        console.error(`${APIName} -  empty response`);
        return "";
      }
      if (resp.data.error) {
        console.error(`${APIName} API - Error: ${resp.data.errorMessage}`);
        return ""
      }
      return resp.data.data.accessToken;
    } catch (error) {
      console.error(error);
    }
  }
}