import { reactive } from "@vue/reactivity";
import { useRouter } from "vue-router";

// @ts-ignore
import { Storage as lStorage } from '@/plugins/storage.js';
import { onMounted } from "@vue/runtime-core";


import { AuthService } from '@/service/auth.service';
import { BEService } from '@/service/backend.service';


export default function useTvsettings() {
    const router = useRouter();
    const state = reactive({
        options: {
            type: 'loop',
            rewind: true,
            gap: '5px',
            padding: {
                left: '0',
                top: '0'
            },
            fixedWidth: '100vw',
            fixedHeight: '156.33px',
            arrows: false,
            autoplay: false,
        },
        toggles: {
            bar: false,
            qa: false,
            fantasy: false,
            ppr: false
        }
    });

    const toRoute = (route) => {
        router.push(route);
    };

    const onClick = () => {
        lStorage.getItem('uuid');
    };

    const checkEnable = () => {
        const checkParent = document.getElementById('checkParent');
        const checkBoxChildren1 = document.getElementById('checkBoxChildren1');
        const checkBoxChildren2 = document.getElementById('checkBoxChildren2');
        const checkBoxChildren3 = document.getElementById('checkBoxChildren3');
        if (checkParent.classList.contains('toggle-checked')) {
            checkBoxChildren1.classList.add('toggle-disabled');
            checkBoxChildren2.classList.add('toggle-disabled');
            checkBoxChildren3.classList.add('toggle-disabled');
        } else {
            checkBoxChildren1.classList.remove('toggle-disabled');
            checkBoxChildren2.classList.remove('toggle-disabled');
            checkBoxChildren3.classList.remove('toggle-disabled');
        }
        if (checkParent.getAttribute("aria-checked") == "false") {
            checkBoxChildren1.classList.add('toggle-disabled');
            checkBoxChildren2.classList.add('toggle-disabled');
            checkBoxChildren3.classList.add('toggle-disabled');
            checkBoxChildren1.classList.add('toggle-color');
            checkBoxChildren2.classList.add('toggle-color');
            checkBoxChildren3.classList.add('toggle-color');
        }
        else {
            checkBoxChildren1.classList.remove('toggle-disabled');
            checkBoxChildren2.classList.remove('toggle-disabled');
            checkBoxChildren3.classList.remove('toggle-disabled');
            checkBoxChildren1.classList.remove('toggle-color');
            checkBoxChildren2.classList.remove('toggle-color');
            checkBoxChildren3.classList.remove('toggle-color');
        }

    }
    const checkEnableLoad = () => {
        const checkParent = document.getElementById('checkParent');
        const checkBoxChildren1 = document.getElementById('checkBoxChildren1');
        const checkBoxChildren2 = document.getElementById('checkBoxChildren2');
        const checkBoxChildren3 = document.getElementById('checkBoxChildren3');
        if (checkParent.getAttribute("aria-checked") == "false") {
            checkBoxChildren1.classList.add('toggle-disabled');
            checkBoxChildren2.classList.add('toggle-disabled');
            checkBoxChildren3.classList.add('toggle-disabled');
            checkBoxChildren1.classList.remove('toggle-color');
            checkBoxChildren2.classList.remove('toggle-color');
            checkBoxChildren3.classList.remove('toggle-color');
        }
        else {
            checkBoxChildren1.classList.remove('toggle-disabled');
            checkBoxChildren2.classList.remove('toggle-disabled');
            checkBoxChildren3.classList.remove('toggle-disabled');
            checkBoxChildren1.classList.add('toggle-color');
            checkBoxChildren2.classList.add('toggle-color');
            checkBoxChildren3.classList.add('toggle-color');
        }
    }


    const saveToggle = async () => {
        const beService = new BEService();
        const auth = new AuthService();
        try {
            const mainEvent = await beService.updateSetting(auth.getCurrentUsername(), state.toggles);
        } catch (error) {
            console.log(error);
        }
    };

    const changeData = (id) => {
        if ((id !== "bar" && state.toggles.bar == true) || id === "bar") {
            state.toggles[id] = !state.toggles[id];
        }
        saveToggle();
    };

    onMounted(async () => {
        const beService = new BEService();
        const auth = new AuthService();
        try {
            const mainEvent = await beService.getSetting(auth.getCurrentUsername());
            if (mainEvent && mainEvent?.data?.data?.toggles) {
                checkEnable();
                state.toggles = mainEvent?.data?.data?.toggles;

            }
        } catch (error) {
            console.log(error);
        }
        checkEnableLoad();

    });
    return { state, toRoute, onClick, checkEnable, closed, changeData, checkEnableLoad }; // salva
}
