import * as docCookies from "@/plugins/cookies";


const setItem = (key, value) => {
    localStorage.setItem(key, value);
    docCookies.setItem(key, value);
}
const getItem = (key) => {
    const valueL = localStorage.getItem(key);
    const valueC = docCookies.getItem(key);

    if (valueL !== valueC) {
        if (valueL && !valueC) {
            docCookies.setItem(key, valueL);
            return valueL;
        } else if (valueC && !valueL) {
            localStorage.setItem(key, valueC);
            return valueC
        }
    }
    return valueL;
}
const removeItem = (key) => {
    localStorage.removeItem(key);
    docCookies.removeItem(key);
}

export const Storage = {
    setItem,
    getItem,
    removeItem
}