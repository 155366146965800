import { reactive } from "@vue/reactivity";
import { useRouter } from "vue-router";


export default function useSignUPForm(props) {
  const router = useRouter();
  const state = reactive({
    code: null,
    isMobilePresent: false
  });

  const toRoute = (route) => {
    router.push(route);
  }

  const toSettings = ()=>{
    router.push('/settings')
  }


  return {state, toRoute, toSettings};
}
