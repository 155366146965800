import { reactive } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { BEService } from '@/service/backend.service';
import { AuthService } from '@/service/auth.service';
import { onMounted } from "@vue/runtime-core";

export default function useCodeForm(props) {
    const router = useRouter();
    const state = reactive({
        code: null,
        valid: true
    });

    const toRoute = (route) => {
        router.push(route);
    };


    const checkCode = async (code) => {
        const beService = new BEService();
        const codeToUpperCase = code.toUpperCase();
        let isExists = false;
        try {
            isExists = await beService.checkCode(codeToUpperCase);
        } catch (error) {
            console.error("Error on checkCode service" - error.message);
            toRoute("/signin");
        }
        if (!isExists) {
            state.valid = false;
            console.error("Code not valid");
            return;
        }
        sessionStorage.setItem("pairingCode", codeToUpperCase);
        const auth = new AuthService();
        auth.isAuthenticated().then(async (isAuth) => {
            if (isAuth) {
                try {
                    await auth.logout();    
                } catch (error) {
                    console.error("Error on logout " + error.message)
                }
                
                console.logout("Signed out");
            }
            toRoute("/signin");
        }).catch((error) => {
            console.error("Error on isAuthenticated - " + error.message);
            toRoute("/signin");
        });


    }

    const buttonClick = async () => {
        checkCode(state.code);
    }

    onMounted(async () => {
        try {
            //const mainEvent = await beService.getMainEvent();
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get("code");
            if (code) {
                state.code = code;
                console.log("Code found on querystring:" + code);
                checkCode(code);
            }
        } catch (error) {
            console.log(error);
        }

    });

    return { state, buttonClick };
}
