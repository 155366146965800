import Home from '@/views/home/Home.vue';
import Leaderboard from '@/views/leaderboard/Leaderboard.vue';
import NotFound from '@/views/notfound/Notfound.vue';
import CodeAccepted from '@/views/pairing/CodeAccepted.vue';
import CodeForm from '@/views/pairing/CodeForm.vue';
import forgotPasswordStep2 from '@/views/password/fpNewPassword.vue';
import forgotPasswordStep1 from '@/views/password/fpSendCode.vue';
import fpVerificationpage from '@/views/password/fpVerificationpage.vue';
import Policies from '@/views/policies/Policies.vue';
import Question from '@/views/question/Question.vue';
import Staytuned from '@/views/question/Staytuned.vue';
import Scorehistory from '@/views/scorehistory/Scorehistory.vue';
import Settings from "@/views/Settings/Settings.vue";
import SignInForm from '@/views/signin/SignInForm.vue';
import Signout from '@/views/signout/Signout.vue';
import SignUPForm from '@/views/signup/SignUPForm.vue';
import VerificationLink from '@/views/signup/VerificationLink.vue';
import Termsofuse from '@/views/Termsofuse/Termsofuse.vue';
import Tvsettings from '@/views/tvsettings/Tvsettings.vue';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
// @ts-ignore
import { AuthService } from '@/service/auth.service';
import { computed, reactive } from "@vue/reactivity";
const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        // component: Home ,
        redirect: '/home'
        // beforeEnter: (to, from, next) => {
        //     const auth = new AuthService();
        //     auth.isAuthenticated().then((value) => {
        //         if (value) {
        //             next('/home');
        //         } else {
        //             next('/signin');
        //         }
        //     }).catch(() => {
        //         next('/signin');
        //     })
        // }
    },
    {
        path: '/home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/settings',
        component: Settings,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/scorehistory',
        component: Scorehistory,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/leaderboard',
        component: Leaderboard,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/policies',
        component: Policies,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/termsofuse',
        component: Termsofuse,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/signout',
        component: Signout,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/question',
        component: Question,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/staytuned',
        component: Staytuned,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/tvsettings',
        component: Tvsettings,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/code-accepted',
        component: CodeAccepted,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/signin',
        component: SignInForm,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/forgot-password',
        component: forgotPasswordStep1,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/forgot-password-new-password',
        component: forgotPasswordStep2,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/verificationpage',
        component: fpVerificationpage,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/signup',
        component: SignUPForm,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/verificationLink',
        component: VerificationLink,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/tv',
        component: CodeForm,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/:catchAll(.*)',
        component: NotFound,
        meta: {
            requiresAuth: false
        }
    }



]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
const routeData = reactive({ params: {}, query: {} });

router.afterEach(route => {
    routeData.params = route.params;
    routeData.query = route.query;
});

window.popStateDetected = false
window.addEventListener('popstate', () => {
    window.popStateDetected = true
})

router.beforeEach(async (to, from, next) => {
    if (to.path == "/signin") {
        console.log("It's signin page. No check of the authentication")
        next();
        return;
    }
    const needsAuth = to.matched.some(route => route.meta.requiresAuth)
    const auth = new AuthService();
    let user = null;
    try {
        user = await auth.isAuthenticated()
    } catch (error) {
        console.error("router.beforeEach - " + error.message);
        next('/signin');
        return;
    }

    // const isBackButton = window.popStateDetected;
    // console.log(!isBackButton?'Nessun tasto back premuto':'Tast back premuto!')
    // window.popStateDetected = false
    if (needsAuth) {
        if (user) {
            next()
            return
        } else {
            console.log("Go to signin")
            next('/signin')
            return
        }
    } else {
        next()
        return
    }

})

export function useParams() {
    return computed(() => routeData.params)
}

export default router
