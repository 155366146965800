<template>
  <ion-page>
    <home-header>
      <template v-slot:left>
        <PairedLeftButton @click="toRoute('/tvsettings')"></PairedLeftButton>
      </template>
    </home-header>

    <ion-content>
      <div class="container-items">
        <ion-row class="question-item">
          <ion-col>
            <p class="points-timer">
              <span>:</span><span id="question-timer" class="">{{ zeroPadding }}</span>
            </p>
          </ion-col>
        </ion-row>
        <ion-row class="question-item">
          <ion-col>
            <span class="item-span">{{ state.question.question }}</span>
          </ion-col>
        </ion-row>
        <ion-row class="question-item">
          <ion-col>
            <div class="question-answer">
              <form>
                <label v-for="(item, index) in state.question.answers" :key="item">
                  <button class="answer-btn" name="question" type="button" @click.once="onClick(index, item)" :value="item">
                    {{ item }}
                  </button>
                </label>
              </form>
            </div>
          </ion-col>
        </ion-row>
      </div>

      <div v-if="state.errorMessage" class="error-messagge">
        <div>
          <p>{{ state.errorMessage }}</p>
          <button @click="closed()">Chiudi</button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import HomeHeader from "@/components/HomeHeader";
import PairedLeftButton from "@/components/PairedLeftButton";
import useQuestion from "@/use/useQuestion";
import { IonContent, IonPage } from "@ionic/vue";
import { addIcons } from "ionicons";
import { add, tvOutline } from "ionicons/icons";


export default {
  name: "Question",
  //components: {IonPage, IonContent, PairedLeftButton, HomeCarousel, LeagueFilter, FabTicket, HomeHeader},
  components: { IonPage, IonContent, PairedLeftButton, HomeHeader },
  setup(props) {
    const { state, onClick, toRoute, closed, timerStart, zeroPadding } = useQuestion(
      props
    );

    addIcons({
      "tv-outline": tvOutline,
      add: add,
    });


    return { state, onClick, toRoute, closed, timerStart, zeroPadding };
  },

};
</script>

<style scoped>
background-content {
  --background: #0a233e;
}

ion-content {
  --background: linear-gradient(180deg,
      var(--ion-color-active-dark) 0%,
      var(--ion-color-active-darker) 100%);
}

.info-text {
  font-family: "Industry Black";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 38px 0 50px 0;
  color: var(--ion-color-primary-text);
}

.icon {
  color: var(--ion-color-primary-text);
  background: transparent;
}

/* Q & A */
.question-item .code-button {
  display: block;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  font-family: "Industry Black";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: 0.38px;
  color: var(--ion-color-primary-text);
}

.code-button-color {
  background: var(--ion-color-active);
  color: var(--ion-color-primary-text);
}

.container-items {
  width: 84.615vw;
  height: 495px;
  position: relative;
  margin: 38px auto 0 auto;
  padding-top: 18px;
}

.container-items:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(180deg,
      var(--ion-color-active) 0%,
      var(--ion-color-passive) 100%);
  opacity: 0.2;
  filter: var(--ion-filter-dark);
  border-radius: 16px;
  z-index: -1;
}

ion-row {
  margin-bottom: 46px;
  position: relative;
}

ion-row:first-child {
  margin-bottom: 38px;
}

ion-col {
  margin: 0 34px;
  padding: 0;
  max-width: calc(100% - 68px);
  display: flex;
}

.points-timer {
  font-family: "Industry Black";
  font-size: 60px;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.38px;
  color: var(--ion-color-primary-text);
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.points-timer span {
  font-family: "Industry Black";
  font-size: 60px;
  /*line-height: 24px;*/
  letter-spacing: 0.38px;
  color: var(--ion-color-primary-text);
  text-align: right;
  width: 49%;
}

#question-timer {
  text-align: left;
  width: calc(50% + 75px);
}

.item-span {
  font-family: "Proxima Nova Bold";
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  letter-spacing: 0;
  color: var(--ion-color-primary-text);
}

.question-answer {
  width: 100%;
  text-transform: uppercase;
}

.question-answer button {
  display: block;
  padding: 0 10px;
  padding-bottom: 2px;
  border: 1px solid var(--ion-color-active);
  position: relative;
  transition: all 0.25s linear;
  text-align: center;
  background: var(--ion-color-active);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  font-family: "Industry Black";
  font-size: 20px;
  /* line-height: 45px; */
  height: 47px;
  width: 100%;
  text-align: center;
  letter-spacing: 0.38px;
  color: var(--ion-color-primary-text);
}

.question-answer label {
  margin: 0 0 15px 0;
  height: 49px;
  cursor: pointer;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}


.question-answer button.answer-btn:focus {
  border: 1px solid var(--ion-color-border-right);
  text-align: center;
}

.error-messagge {
  position: absolute;
  z-index: 99999;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
}

.error-messagge div {
  margin: auto;
  width: 86%;
  background: #fff;
  padding: 25px;
  color: black;
  border-radius: 8px;
}

.error-messagge div button {
  padding: 12px;
  background: #0d4173;
  color: #fff;
}

.error-messagge div p {
  font-size: 20px;
}
</style>
