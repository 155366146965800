import { reactive } from "@vue/reactivity";
import { useRouter } from "vue-router";


export default function useCodeForm(props) {
  const router = useRouter();
  const state = reactive({
    code: true,
  });

  const buttonClick = () => {
    router.push('/signin');
  }

  return { state, buttonClick };
}
