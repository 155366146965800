import { useParams } from "@/router";
import { AuthService } from '@/service/auth.service';
import { reactive } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useRouter } from "vue-router";

export default function useSignout(props) {
    const router = useRouter();
    const state = reactive({
        options: {
            type: 'loop',
            rewind: true,
            gap: '5px',
            padding: {
                left: '0',
                top: '0'

            },
            fixedWidth: '100vw',
            fixedHeight: '156.33px',
            arrows: false,
            autoplay: false
        }
    });

    const toRoute = (route) => {
        router.push(route);
    };

    const signout = async () => {
        try {
            const auth = new AuthService();
            await auth.logout();
            console.log("Signout done");
            // toRoute('/signin');
            window.location='/signin'
        } catch (error) {
            console.error(error);
            toRoute("/");
        }

    };


    onMounted(async () => {
        const params = useParams().value;
        //Insert here start command
    });
    return { state, toRoute, signout };
}
